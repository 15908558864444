@font-face {
    font-family: 'Myriad Pro';
    src: url('./MyriadPro-BlackSemiCn.eot');
    src: url('./MyriadPro-BlackSemiCn.eot?#iefix') format('embedded-opentype'),
        url('./MyriadPro-BlackSemiCn.woff2') format('woff2'),
        url('./MyriadPro-BlackSemiCn.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('./MyriadPro-Bold.eot');
    src: url('./MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./MyriadPro-Bold.woff2') format('woff2'),
        url('./MyriadPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('./MyriadPro-Light.eot');
    src: url('./MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./MyriadPro-Light.woff2') format('woff2'),
        url('./MyriadPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


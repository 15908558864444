//*******/
// FONT */
//*******/
$font-family: 'Myriad Pro';
$font-size: 15px;
$font-size-unitless: 15;

//*************/
// DIMENSIONS */
//*************/
$top-nav-height: 110px;
$menu-width: 332px;
$submenu-width: 182px;
$menu-small-width: 150px;
$menu-breakpoint: 1300px;

$footer-height: 50px;

$topbar-height: 65px;
$topbar-height-with-margin: 115px;

//*************/
// Z-Indices */
//*************/
$z-index-topbar: 999;
$z-index-quill: 9001;
$z-index-popover: 9999;
$z-index-loading-overlay: 10000;
$z-index-nav-submenu: 10012;
$z-index-nav-header: 10012;
$z-index-modal: 10020;
$z-index-notifications: 10021;
$z-index-topmost: 99999;
$z-index-below-modal: 10019;
//*********/
// COLORS */
//*********/
:root {
  --color-primary: #1c3553;
  --color-secondary: #fbbc41;
  --color-secondary-light: #fde4b4;
}

$color-white: #ffffff;
$color-default-border: #c9c9c9;
$color-primary: #1c3553;
$color-primary-hover: #132e48;
$color-primary-light: lighten($color-primary, 13);
$color-primary-superlight: #e9f2fb;
$color-secondary: #fbbc41;
$color-secondary-hover: #f09936;
$color-default-hover: #e9f2fb;
$color-table-hover: #e5f1f7;

$color-error: #fa6e6e;
$color-success: #76bb7d;
$color-blue-light: #87428c;
$color-blue-light2: #2f87f3;
$color-blue-white: #98c5fc;

$color-bg-dark: #e5e5e5;
$color-bg-medium: #ffffff;
$color-big-icons: #dd8927;

$color-text: #272d30;
$color-text-light: #656768;
$color-text-muted: #aaa;
$color-text-disabled: #ccc;
$color-text-canceled: #bbb;
$color-text-placeholder: #747474;
$color-dot-disabled: rgb(14, 9, 9);
$color-button-disabled: #f0f0f0;
$color-button-text-disabled: #bcbcbc;
$color-imprint-footer-grey: #99a0aa;
$text-placeholder: #0079b3;

$color-menu-background: #173552;
$color-menu-active-item: #ffffff;
$color-menu-icon: #bac6d1;
$color-menu-text: #bac6d1;
$color-menu-item-divider: #242930;
$color-menu-text-dark: #3a4149;
$color-submenu: #132e48;
$color-submenu-imprint: #e2e5e8;

$color-modal-footer-background: #f9fafb;
$color-modal-footer-border: #e3e3e3;

$color-shadow: #ccc;

$color-line-light: #e3e3e3;

$color-input-bar: #c6c6c6;

$color-attention: #eb244d;

$badge-color: #b2b2b2;
$badge-color-light: #ebf4ff;
$badge-color-alert: #87428c;
$badge-color-success: #a6da98;

$badge-text-color: #b2b2b2;
$badge-border-color: $badge-text-color;

$color-expenses: #ada8a3;
$color-salesTax: #c5c2bc;
$color-tradeTax: #dddbd7;
$color-incomeTax: #e8e7e4;

$color-sales-top1: #0079b3;
$color-sales-top2: #707e8e;
$color-sales-top3: #a9b2bb;
$color-sales-top4: #e2e5e8;
$color-sales-top5: #dbdbdb;

$barchart-color: #0079b3;

$topbar-color: #f2f4f6;

$menu-text-color: #ffffff;
$submenu-text-color: #e2e5e8;

// round buttons
$size-rounded-small: 24;
$button-rounded-size: 24;
$button-rounded-fontsize: 14;
$button-rounded-icon-color: #5a5a5a;

// _grid.sass
$grid-columns: 12;
$gutter-width: 1rem;
$outer-margin: 2rem;
$gutter-compensation: $gutter-width / 2 * -1;
$half-gutter-width: $gutter-width / 2;

$breakpoints: xs 480px 480px, sm 768px 768px, md 1024px 1024px, lg 1300px 1300px;

$flexboxgrid-max-width: 1300px;

// used for _breakpoint.sass
$breakpoint-lg: nth($breakpoints, 4);
$viewport-lg: nth($breakpoint-lg, 2);

//**********/
// SHADOWS */
//**********/
$box-shadow-subscription-box: 0px 0px 5px 2px rgba(0, 0, 0, 0.04);
$box-shadow-popover: 0px 0px 10px 3px rgba(0, 0, 0, 0.06);
$box-shadow-modal: 0 3px 9px rgba(0, 0, 0, 0.5);
$box-shadow-box: 0 0 10px 0 #ccc;
$box-shadow-tooltip: 0 2px 14px 2px rgba(0, 0, 0, 0.13);

//**********/
// IMPRESS */
//**********/
$impress-topbars-height: 65px;
$impress-content-nav-separator-width: 2px;
$impress-nav-section-width: 350px;
$impress-quill-picker-width: 178px;

//**********/
// AG-GRID */
//**********/
$ag-grid-head-height: 76px;
$ag-grid-pagination-height: 40px;

//*************/
// Button */
//*************/
$topbar-button-height: 36px;

@import "../abstracts/_variables";
@import "../abstracts/_mixins";

$icon_size: 20;

.column {
	&Wrapper {
		position: relative;
		&.dropdownVisible {
			.columnContainer {
				.columnFormDropdown {
					visibility: visible;
				}
			}
		}
	}
	&Container {
		&:hover,
		&.documentOverlayElement-focus {
			.columnFormDropdown {
				visibility: visible;
			}
		}
		&:after {
			position: absolute;
			top: 0;
			right: -60px;
			width: 50px;
			height: 100%;
			content: " ";
		}
	}
	&Chain {
		position: absolute;
		color: #aaaaaa;
		@include rem(bottom, 8);
		@include rem(right, -22);
	}
	&RemoveButton {
		visibility: hidden;
		position: absolute;
		justify-content: center;
		align-items: center;
		background: white;
		border: none;
		border-radius: 50%;
		box-shadow: 0px 1px 1px 0px black;
		color: $button-rounded-icon-color;
		outline: none;
		@include rem(top, 0);
		@include rem(right, 0);
		@include rem(width, 15);
		@include rem(height, 15);
		@include rem(padding, 0);
		@include rem(font-size, 5);
		&:hover {
			color: $color-primary;
		}
		&:before {
			margin: 0;
			@include rem(width, 15px);
			@include rem(height, 15px);
			@include rem(line-height, 15px);
		}
		&-visible {
			visibility: visible;
		}
	}
	&Header {
		display: flex;
		position: relative;
		cursor: pointer;
		width: 726px;
		min-height: 20px;
		font-weight: 600;
		@include rem(padding, 5, 0, 8, 0);
		&-underlined {
			border-bottom: 1px solid $color-line-light;
		}
	}
	&Header,
	&FormHeader {
		> div {
			position: relative;
			white-space: nowrap;
			&:nth-child(n) {
				margin-right: 12px;
			}
			&:first-child {
				position: absolute;
				transform: rotate(90deg);
				margin-right: 0;
				@include rem(left, -10);
			}
			&:last-child {
				flex: 0 0 #{$icon_size + 10}px;
				@include rem(margin-right, -$icon_size - 10);
			}
		}
		input:disabled {
			color: $color-text;
			-webkit-text-fill-color: $color-text;
		}
	}
	&_description {
		flex-basis: 0;
		flex-grow: 1;
	}
	&_number {
		width: 85px;
		flex-basis: 85px;
		flex-grow: 0;
	}
	&_amount {
		display: flex;
		justify-content: flex-start;
		flex-basis: 0;
		flex-grow: 2;
		max-width: 150px;
		padding-left: 10px;
		.selectInput {
			position: absolute;
			top: -3px;
			right: 0;
			width: 86px;
			&:hover {
				&:before {
					position: absolute;
					bottom: 5px;
					width: 100%;
					height: 1px;
					border-bottom: 1px #cccccc dashed;
					z-index: 1;
					content: " ";
				}
			}
			&:not(.selectInput-active) {
				.Select-clear-zone,
				.Select-arrow-zone {
					display: none;
				}
			}
			.selectInput_bar:before,
			.selectInput_bar:after {
				background: transparent;
				display: none;
			}
			.Select-menu-outer {
				width: 180px;
				margin-left: -11px;
			}
			.Select-control {
				height: 28px;
				border: none;
				cursor: text;
				.Select-multi-value-wrapper {
					height: 26px;
				}
				.Select-placeholder,
				.Select-value {
					line-height: 26px;
				}
				.Select-input {
					height: 26px;
					& > input {
						padding-top: 4px;
					}
				}
				.Select-clear-zone,
				.Select-arrow-zone {
					width: 15px;
					line-height: 20px;
				}
				.Select-arrow-zone {
					padding-left: 3px;
					padding-right: 0;
					line-height: 8px;
				}
			}
		}
	}
	&_vat {
		width: 45px;
		text-align: right;
	}
	&_price {
		width: 85px;
		text-align: right;
	}
	&_discount {
		width: 55px;
		text-align: right;
	}
	&_total {
		width: 90px;
		text-align: right;
		margin-right: 0 !important;
		.input {
			text-align: right;
		}
	}

	&Header {
		position: relative;
		cursor: pointer;
		width: 726px;
		min-height: 20px;
		border-bottom: 1px solid $color-line-light;
		font-weight: 600;
		@include rem(padding, 5, 0, 8, 0);

		:first-child {
			@include rem(margin-left, -$icon_size);
		}

		:last-child {
			@include rem(margin-right, -$icon_size - 10);
		}

		> div {
			overflow: hidden;
			white-space: nowrap;

			&:nth-child(4) {
				justify-content: center;
			}

			&:nth-child(5) {
				@include rem (padding-right, 0);
			}				
		}

		&Wrapper {
			position: relative
		}
	}

	&Form {
		&Header {
			> div {
				&:nth-child(n) {
					.input {
						padding-top: 0px;
						padding-bottom: 5px;
						height: auto;
					}
				}
				&:nth-child(5) {
					width: 55px;
					text-align: right;
				}
				&:nth-child(8) {
					.input {
						text-align: right;
					}
				}
			}
		}

		&Dropdown {
			position: absolute;
			right: -50px;
			top: 0px;
			visibility: hidden;

			.dropdownButton.button-icon-plus:before {
				transition: none
			}
			
			.dropdownItem {
				cursor: pointer
			}				
		}
	}
}

@-moz-document url-prefix() {
	.column_amount {
	  .selectInput {
		top: -4px;
	  }
	}
}
@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.customer-list-component-wrapper {
  .customer-list-wrapper {
    .icon-user-wrapper {
      height: 100%;
      display: flex;
    }

    .ag-cell {
      .icon.icon-factory {
        font-size: 16px;
        color: $color-primary;
      }
    }

    .ag-filter {
      .ag-set-filter-item {
        .ag-set-filter-item-value {
          margin-left: 7px !important;
        }

        .icon,
        .icon-user-wrapper {
          margin: 0 0 0 8px;
        }

        .icon.icon-factory {
          padding-top: 3px;
          font-size: 14px;
          color: $color-primary;
        }
      }
    }
  }
  .accessories-list {
    .popover-wrapper {
      right: 28px !important;
    }
  }
}


.audtio-gray-label {
  color: #40444E;
}

.audtio-red-label {
  color: #AE0E16;
}

.audtio-green-label {
  color: $color-success;
}


.customer-list-old-component-wrapper {
  .customer-list-head {
    position: relative;
    width: 100%;
    height: 300px;
    background: $color-white;

    .customer-list-head-content {
      position: relative;
      width: 925px;
      margin: 0 auto;
      padding-top: 110px;

      .filter-component-wrapper {
        float: right;
      }
    }
  }

  .customer-list-wrapper {
    position: relative;
    width: 925px;
    min-height: 500px;
    margin: -150px auto 50px;
    padding: 10px 30px 0;

    .empty-list-box {
      text-align: center;
      padding: 85px 0;

      .text-placeholder {
        font-size: 188px;
      }

      .text-h2 {
        padding: 0 0 10px;
      }

      .button-component-wrapper {
        margin: 30px 10px 0;
        display: inline-block;
      }
    }

    .customer-list-cell-dropdown {
      position: relative;
      top: 3px;
    }

    .customer-list-pagination {
      text-align: center;
      padding: 50px 0;
    }
  }
}

@import '../../../abstracts/_variables';
@import '../../../abstracts/_mixins';

.menuHeader {
  .menu-overlay-admin-indicator {
    position: fixed;
    top: -60px;
    left: -60px;
    width: 120px;
    height: 120px;
    padding: 95px 0 0 25px;
    font-weight: 600;
    background: yellow;
    z-index: 1000001;
    @include vendor-prefix('transform', 'rotate(-45deg)');
  }

  position: absolute;
  z-index: $z-index-nav-header;
  display: flex;
  align-items: center;
  pointer-events: none;
  width: 100%;
  top: 0;
  @include rem(min-height, 140);

  @include breakpoint-md {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include rem(min-height, 150);
    @include rem(padding-top, 25);
  }

  @include breakpoint-lg {
    &.submenu-visible {
      .menuHeader_logo {
        & > :nth-child(1) {
          display: none !important;
        }

        & > :nth-child(2) {
          display: block !important;
        }
      }
    }
  }
}

.menuHeader_logo {
  z-index: 10;
  pointer-events: auto;

  @include breakpoint-lg {
    width: 100%;
    @include rem(margin-right, 0);
  }

  & > :nth-child(1) {
    @include breakpoint-md {
      display: none;
    }
  }

  & > :nth-child(2) {
    @include breakpoint-lg {
      display: none;
    }
  }

  &-visible {
    display: block !important;
    width: auto;
    fill: $color-white;

    @include breakpoint-lg {
      @include rem(margin-left, 25, !important);
    }
  }

  &-invisible {
    display: none;
  }
}

.menuHeader_search {
  z-index: 10;
  display: flex;
  color: $color-white;
  pointer-events: auto;
  @include rem(margin-left, 62);
  @include rem(margin-top, 5);
  @include rem(font-size, 16);

  @include breakpoint-md {
    @include rem(margin-top, 23);
    @include rem(margin-right, 0);
    @include rem(margin-left, 0);
  }

  &:before {
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: all 0.2s ease-in;
  }
}

.menuHeader_newsfeed {
  position: relative;
  z-index: 10;
  display: flex;
  color: $color-white;
  pointer-events: auto;

  @include rem(margin-left, 20);
  @include rem(margin-top, 5);
  @include rem(font-size, 16);

  @include breakpoint-md {
    @include rem(margin-top, 28);
    @include rem(margin-right, 0);
    @include rem(margin-left, 0);
  }

  &:before {
    display: flex;
    align-items: center;
  }

  &:hover {
    transition: all 0.2s ease-in;
  }

  .menuHeader_badge {
    position: absolute;
    top: -16px;
    left: 7px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    background: $color-attention;
    @include disable-user-select();
  }
}

.menuHeader_header {
  z-index: 1;
  display: flex;
  align-items: center;

  @include rem(min-height, 128);

  @include breakpoint-md {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @include rem(min-height, 150);
    @include rem(padding-top, 60);
  }
}
.auric-small-image {
  width: 67px;
}

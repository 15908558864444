@import "../abstracts/_variables";
@import "../abstracts/_mixins";

.margin-top-unset {
	margin-top: unset;
}
.pointer {
	cursor: pointer;
}

.wrapper-has-topbar {
	margin-top: $topbar-height;
	background-color: $color-white;
}

.faq-add-edit-content {
	h6 {
		font-size: 24px;
		line-height: 36px;
		margin: 0px;
		display: flex;
		align-items: flex-end;
		color: #40444E;
		letter-spacing: 0.3px;
	}
	.faq-input-add {
		background: #F7F8FA;
		border-radius: 8px;
		border: none;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 10px 0px;
		width: 100%;
		padding: 10px;
		outline: none !important;
	}
}

.faq-add-edit-content .faq-input-add:focus-visible {
	outline: none !important;
}

.wrapper-has-topbar-with-margin {
	margin-top: $topbar-height-with-margin;
}
.audito-pl55-pr-15 {
  padding-left: 55px !important;
  padding-right: 15px !important;
}

.page.no-side-margin {
	margin-left: 0!important;
	margin-right: 0!important;
	width: 100%!important;
}

.page.no-top-margin {
	margin-top: 0!important;
	margin-bottom: 0!important;
	width: auto!important;
}

.page.fullsize-view {
	position: absolute;
	top: 0;
	bottom: 0;
	left: $menu-small-width;
	right: 0;

	@media (min-width: $menu-breakpoint) {
		left: $menu-width;
	}
}

.page.fullsize-blank-view {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

.Select-control {
	border: 0 none;
	border-bottom: 1px solid #cccccc;
	box-shadow: none !important;
	border-radius: 0;
	.Select-value,
	.Select-placeholder,
	.Select-input {
		padding-left: 0 !important;
	}
}

.toggleInput {
    position: relative;
    align-items: center;

	.notes-alert-label {
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		color: #40444E;
	}
}

.notes-display-wrapper {
	position: relative;
	cursor: pointer;
	background-color: #F7F8FA;
    padding: 10px 20px 20px 20px;
    border-radius: 16px;

	&:before {
		content: "";
		position: absolute;
		visibility: hidden;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 16px;
		border: 1px dashed $color-text-placeholder;
	}

	&:hover:before {
		visibility: visible;
	}
}

.boxHeader_actions-pulled-up {
	margin-bottom: -15px;

	.transactionEdit_textSwitch {
		color: $color-text-muted;
	}
}

.hover-underline:hover {
	text-decoration: underline;
}

.zIndex-10001 {
	z-index: 10001;
}

.ql-editor.ql-blank {
	&::before {
		left: 0!important;
	}
	
}
.Select-input,
.Select-placeholder {
	padding-left: 0!important;
}

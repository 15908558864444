@import "../abstracts/variables";
@import "../abstracts/mixins";

.page {
	position: relative;

	.layout-basic & {
		text-align: center;
		margin: 0 auto;
		width: 420px;
	}
}

.pagebox {
	&_heading {
		margin-bottom: 12px;
	}

	&_subheading {
		margin-bottom: 26px;
	}

	&_content {
		display: flex;

		&.row, &-divided.row {
			> :first-child {
				padding-right: 30px;
			}

		}

		&-divided.row {
			> :nth-child(n+2) {
				border-top: 1px solid $color-input-bar;
				padding-top: 10px;
			}
		}
	}
	
	&_footer {
		border-top: 1px solid $color-input-bar;
		padding-top: 60px;
	}
}
@import "../../abstracts/_variables";
@import "../../abstracts/_mixins";

#notification-component-wrapper {
	position: fixed;
	top: 84px;
	right: 20px;
	z-index: $z-index-notifications;

	&.absolute-top {
		top: 20px;
	}

	.notification-component {
		position: relative;
		width: 330px;
		height: 98px;
		margin-bottom: 20px;
		border: 1px solid $color-modal-footer-border;
		background: $color-white;
		border-radius: 10px;
		box-shadow: $box-shadow-popover;
		opacity: 1;
		cursor: pointer;
		transform: scale(1, 1);
		transition: opacity 0.4s ease-out, transform 0.2s ease-in-out;

		&.opening {
			transform: scale(0, 0);
		}

		&.hiding {
			opacity: 0;
		}

		.points-indicator {
			position: absolute;
			top: 5px;
			left: 10px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 11px;
			color: $color-white;
			background: $color-primary;
			border-radius: 50%;
		}


		.icon-close2 {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 10px;
			color: $color-text-light;
			display: block;
			cursor: pointer;
		}

		.content {
			margin: 10px;
			display: flex;

			.left-col {
				width: 75px;
				height: 75px;
				line-height: 75px;
				background: #F1F8FF;
				text-align: center;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				.icon {
					font-size: 28px;
				}

				&.success {
					background: lighten($badge-color-success, 20%);

					.icon {
						color: $color-success;
					}
				}

				&.error {
					background: lighten($badge-color-alert, 10%);

					.icon {
						color: $color-error;
					}
				}

				img {
					display: inline;
				}
			}

			.right-col {
				width: 250px;
				margin-left: 20px;
				line-height: 1.2em;
				flex-direction: column;
				justify-content: center;
				display: flex;

				.title {
					margin-bottom: 4px;
					font-weight: 600;
				}

				.message {
					font-size: 14px;
					color: $color-text-light;
					white-space: pre-wrap;
   					overflow-wrap: break-word;
				}
			}
		}
	}
}

@import '../../abstracts/_variables';
@import '../../abstracts/_mixins';

.icon-settings,
.icon-download {
  display: flex;
  align-items: center;
  margin-left: 20px;
  &::before {
    font-size: 32px;
    position: relative;
    top: -8px;
    margin-right: 8px;
  }
}
.direction-rtl {
  direction: rtl;
}
.topbar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: $menu-small-width;
  height: $topbar-height;
  z-index: $z-index-topbar;
  background: $color-white;
  border-bottom: 1px solid $color-line-light;
  @include vendor-prefix('box-shadow', $box-shadow-subscription-box);

  @media (min-width: $menu-breakpoint) {
    left: $menu-width;
  }

  &.full-page-width {
    left: 0;
  }

  &.absolute {
    position: absolute;
  }

  .topbar-buttons {
    .button-outline {
      position: relative;
      height: $topbar-button-height;
      line-height: 38px;
      padding: 0 40px;
      border-radius: 30px;
      font-size: 15px;
      box-sizing: border-box;
      border-radius: 40px;

      padding: 0;
      display: flex;
     justify-content: center;

      .icon {
        // position: absolute;
        // left: 17px;
      }
    }
    .button-primary {
      width: 200px;
      background: $color-primary;

      .icon {
        left: 25px;
      }
      .icon-device,
      .icon-add-circle,
      .icon-help-circle,
      .icon-checklist {
        font-size: 35px;
        // top: -9px;
        margin-top: -9px;
        margin-right: 0px !important;

      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .button-primary-small {
      width: auto;
      background: $color-primary;

      .icon {
        left: 25px;
      }
      .icon-device,
      .icon-add-circle,
      .icon-help-circle,
      .icon-checklist,
      .icon-edit {
        font-size: 35px;
        // top: -9px;
        margin-top: -9px;
        margin-right: 0px !important;
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .help {
      width: 145px;
      background: $color-primary;
      .icon {
        left: 25px;
        .icon-device,
        .icon-add-circle,
        .icon-help-circle,
        .icon-checklist,
        .icon-edit {
          font-size: 35px;
          // top: -9px;
          margin-top: -9px;
          margin-right: 0px !important;
        }
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .editbtnPdf {
      width: 140px;
      background: $color-primary;
      min-width: unset;
      &:link,
      &:visited,
      &:focus,
      &:hover,
      &:active {
        background: $color-primary !important;
      }

      .icon {
        left: 25px;
        float: inherit;
        margin-right: 0px !important;
        
        .icon-device,
        .icon-add-circle,
        .icon-help-circle,
        .icon-checklist,
        .icon-edit {
          font-size: 35px;
          // top: -9px;
          float: inherit;
          margin-right: 0px !important;
        }
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .faq {
      width: 145px;
      background: $color-primary;
      min-width: unset;
      display: flex;
      justify-content: center;
      &:hover {
        background: $color-primary-hover !important;
      }
      .icon {
        margin-top: -9px;
        float: inherit;
        margin-right: 0px !important;
        // left: 25px;
        // top: -10px !important;
        .icon-device,
        .icon-add-circle,
        .icon-help-circle,
        .icon-checklist,
        .icon-edit {
          font-size: 35px;
          // top: -9px;
        }
      }
      .text-content {
        margin-left: 10px;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .faq-compo-aduito {
      width: 260px !important;
    }
    .faq-add {
      width: 255px;
      background: $color-primary;
      min-width: unset;
      .icon {
        left: 25px;
        .icon-device,
        .icon-add-circle,
        .icon-help-circle,
        .icon-checklist,
        .icon-edit {
          font-size: 35px;
          // top: -9px;
          margin-top: -9px;
          margin-right: 0px !important;
        }
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .edit-view {
      width: 130px;
    }
    .button-secondary {
      width: 200px;
      background: $color-secondary;

      .icon {
        left: 25px;
        margin-right: 0px !important;
      }
      .icon.loader_spinner {
        left: 0px;
      }
      .icon-device,
      .icon-add-circle,
      .icon-help-circle,
      .icon-checklist {
        font-size: 35px;
        // top: -9px;
        margin-top: -9px;
        margin-right: 0px !important;
      }
      .text-content {
        margin-left: 10px;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .button-secondary-small {
      width: auto;
      background: $color-secondary;

      .icon {
        left: 25px;
      }
      .icon-device,
      .icon-add-circle,
      .icon-help-circle,
      .icon-checklist,
      .icon-edit {
        font-size: 35px;
        // top: -9px;
        margin-top: -9px;
        margin-right: 0px !important;
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
    .edit {
      width: auto;
      background: $color-secondary;
      .icon {
        left: 25px;
        top: -2px;
        .icon-device,
        .icon-add-circle,
        .icon-help-circle,
        .icon-checklist,
        .icon-edit {
          font-size: 20px;
          // top: -9px;
          margin-top: -9px;
          margin-right: 0px !important;
        }
      }
      .text-content {
        margin-left: 10px !important;
        flex-direction: initial !important;
        line-height: 2.5;
        display: initial !important;
        justify-content: initial !important;
      }
    }
  }

  .topbar-back-button,
  .topbar-view-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: $topbar-height;
    padding-top: 21px;
    text-align: end;
    border-right: 0;
    cursor: pointer;

    .icon-credit_card {
      font-size: 24px;
      line-height: 16px;
    }
  }

  .topbar-back-button {
    .icon {
      pointer-events: none;
    }
  }

  .topbar-view-icon {
    cursor: default;
    .icon-contacts,
    .icon-tickets,
    .icon-device,
    .icon-accessories,
    .icon-radiovector,
    .icon-health,
    .icon-rollen,
    .icon-user {
      font-size: 35px;
      margin-top: -20px;
    }

    .icon-timetracking {
      font-size: 20px;
      line-height: 19px;
    }
  }

  .topbar-content {
    // position: absolute;
    left: $topbar-height;
    top: 0;
    right: $topbar-height;
    height: $topbar-height;
    width: 98%;
    margin: 0 auto;

    &.no-back-button {
      left: 16px;
    }

    &.no-dropdown-menu-button {
      right: 17px;
    }

    .topbar-title {
      font-family: 'Myriad Pro';
      float: left;
      margin-left: 40px;
      line-height: 65px;
      font-weight: 600;
      font-size: 24px;
      max-width: 400px;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      white-space: nowrap;

      .topbar-subtitle {
        display: inline-block;
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        color: $color-text-light;
      }

      sup {
        top: -4px;
      }
    }

    .topbar-buttons {
      float: right;
      margin-right: 0px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-top: -6px;

      .button-component-wrapper {
        .button-text {
          display: flex;
        }
      }

      .button {
        @include vendor-prefix('transition', 'none');

        &:hover {
          @include vendor-prefix('transition', 'all .1s ease-in-out');
        }
      }

      > div {
        float: left;
        margin-left: 10px;
      }
    }
  }

  .topbar-dropdown-menu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: $topbar-height;
    height: $topbar-height;
    padding-top: 22px;
    text-align: center;
    border-left: 1px solid $color-line-light;
    cursor: pointer;

    .icon-menu {
      font-size: 22px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      transform: translate(-50%, -50%);
    }
  }
}
.special {
  .icon-add-circle {
    font-size: 35px;
    top: -9px;
    margin-top: -9px;
  }
}
.font-24 {
  font-size: 24px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.font-16 {
  font-size: 16px !important;
}
.large-button {
  width: 250px !important;
}
.icon-save-square {
  font-size: 35px;
  top: -9px;
  margin-top: -10px !important;
}

.dropdown-faq {
  position: relative;
  display: inline-block;
}

.dropdown-content-faq {
  display: none;
  position: absolute;
  z-index: 1;
  background: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 350px;
  word-break: break-word;
  padding: 26px 24px;

  .dropdown-show-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    cursor: auto;

    .dropdown-show-wrapper-content {
      width: 20%;
      cursor: auto;

      span {
        padding: 10px;
        background: $color-secondary;
        border-radius: 50%;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.dropdown-header-tollbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: auto;
}

.dropdown-user-audito {
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin-top: 10px;

  .dropdown-header-content {
    position: relative;
    background: $color-secondary;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;

    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
      background: $color-secondary-hover !important;
      color: $color-white;
    }
  }
  .topbar-view-icon {
    position: relative;
    height: 40px;
    padding: 0px;
    text-align: center;
    .icon {
      font-size: 40px;
      text-align: center;
      background: $color-primary;
      color: $color-white;
      border-radius: 50%;
      width: 50px;
      cursor: pointer;
      height: 50px;
      &::before {
        font-size: 35px;
      }
    }
  }
  .dropdown-menu.show {
    display: grid;
    background: $color-white;
    padding: 10px;
    @include vendor-prefix('box-shadow', $box-shadow-subscription-box);
  }
  a.dropdown-item {
    padding: 5px 10px;
  }
  .dropdown {
    button#dropdown-basic {
      background: transparent;
      border: none;
    }
  }

  a.menuItem {
    height: 100%;
    width: 100%;
    min-height: 100%;
    text-align: center;
    margin-top: 10px;
    background: $color-primary;
    color: $color-white !important;
    border-radius: 40px;
    padding: 0px;

    span {
      position: relative;
      top: 0;
      color: $color-white;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.audito-version-dis {
  height: 5px;
  position: relative;
  color: #bac6d1;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
  width: $menu-small-width;
  white-space: nowrap;
  min-height: 0px;
  max-height: 0px;
  line-height: 21px;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  cursor: auto;
  margin-bottom: 0px;
  margin-top: 0px;
}


.audtio-gray-label {
  color: #40444E !important;
}

.audtio-red-label {
  color: #AE0E16 !important;
}

.audtio-green-label {
  color: $color-success !important;
}
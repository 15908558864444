@import '../abstracts/_variables';
@import '../abstracts/_mixins';

.ct-series-a .ct-line {
  stroke: $color-primary;
}

.ct-series-a .ct-point {
  stroke: $color-primary;
}

.row-no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.col-no-gutter {
  padding-left: 0;
  padding-right: 0;
}

.col-no-gutter-left {
  padding-left: 0 !important;
}

.col-no-gutter-right {
  padding-right: 0 !important;
}

.col-gutter-right-40 {
  padding-right: 40px !important;
}

.col-gutter-left-20 {
  padding-left: 20px !important;
}

.col-gutter-right-20 {
  padding-right: 20px !important;
}

.cropper-modal {
  background-color: $color-white;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  position: absolute;
  margin: auto;
  left: 45%;
  color: #666;
  font-size: 3.2rem;
  font-weight: 500;

  content: '\02C6';
  top: 8%;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child::before {
  position: absolute;
  margin: auto;
  left: 45%;
  color: #666;
  font-size: 3.2rem;
  font-weight: 500;

  content: '\02C7';
  bottom: -2%;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-img-text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.img-contain {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.rotate-icon {
  transform: rotate(-90deg);
  margin-top: -2px;
}

.sidebar-item-title {
  font-size: 15px;
  font-weight: 600;
  color: #40444e;
  display: flex;
  gap: 6px;
}

.detail-variant-modal-img,
.accessories-variant-table-img {
  cursor: pointer;
  width: 50px;
  aspect-ratio: 1/ 1;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.next-step-warning,
.send-documents-warning {
  display: flex;
  height: 50px;
  border: 1px solid #fbbc41;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  padding-left: 24px;
  margin: 32px 0;
  background: rgba(255, 241, 225, 0.5);
}

.send-documents-warning {
  margin: 0;
  margin-bottom: 12px;
}

.next-step-warning-icon,
.send-documents-warning-icon {
  font-size: 38px;
  color: #fbbc41;
  top: -2px;
  position: relative;
}

.next-step-warning-text,
.send-documents-warning-text {
  font-size: 16px;
  color: #fbbc41;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

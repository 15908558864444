@import '_variables';

// _breakpoint.sass
@mixin breakpoint-md {
  @media (max-width: $viewport-lg - 1px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media (min-width: #{$viewport-lg}) {
    @content;
  }
}

@mixin breakpoint-high {
  @media (min-height: 900px) {
    @content;
  }
}

@mixin breakpoint-height-md {
  @media (max-height: 1000px) {
    @content;
  }
}

@mixin breakpoint-height-sm {
  @media (max-height: 800px) {
    @content;
  }
}

@mixin breakpoint-menu-sm {
  @media (max-height: 850px) {
    @content;
  }
}

@mixin breakpoint-menu-lg {
  @media (min-height: 850px) {
    @content;
  }
}

@mixin breakpoint-md-menu-sm {
  @media (max-width: #{$viewport-lg - 1px}) and (min-height: 850px) {
    @content;
  }
}

@mixin font-face($name, $path) {
  @font-face {
    font-family: $name;
    src: url('#{$path}/#{$name}.woff') format('woff'),
      url('#{$path}/#{$name}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin vendor-prefix($name, $value...) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position==both {
    $position: 'before, &:after';
  }
  &:#{$position} {
    @if $styles {
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      width: 16px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    @content;
  }
}

@mixin trbl($num, $position: absolute) {
  position: $position;
  top: $num;
  right: $num;
  bottom: $num;
  left: $num;
}

@function px-to-rem($val, $base: $font-size-unitless) {
  @if type-of($val) ==number and unitless($val) {
    @if $val==0 {
      @return 0;
    }

    @return #{$val}px;
  }

  @return $val;
}

@mixin rem($property, $values...) {
  $max: length($values);
  $value: '';

  @for $i from 1 through $max {
    $value: #{$value + px-to-rem(nth($values, $i))};

    @if $i < $max {
      $value: #{$value + ' '};
    }
  }

  #{$property}: $value;
}

// custom mixins
@mixin disable-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@mixin truncate-text() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin triangle($direction, $size, $color) {
  width: 0;
  height: 0;
  pointer-events: none;

  @if $direction == 'up' {
    border-left: px-to-rem($size) solid transparent;
    border-right: px-to-rem($size) solid transparent;
    border-bottom: px-to-rem($size) solid $color;
  } @else if $direction == 'down' {
    border-left: px-to-rem($size) solid transparent;
    border-right: px-to-rem($size) solid transparent;
    border-top: px-to-rem($size) solid $color;
  } @else if $direction == 'right' {
    border-top: px-to-rem($size) solid transparent;
    border-bottom: px-to-rem($size) solid transparent;
    border-left: px-to-rem($size) solid $color;
  } @else if $direction == 'left' {
    border-top: px-to-rem($size) solid transparent;
    border-bottom: px-to-rem($size) solid transparent;
    border-right: px-to-rem($size) solid $color;
  }
}

@charset "UTF-8";
@font-face {
    font-family: "app_icon";
    src: url('../iconfont/app_icon.eot');
    src: url('../iconfont/app_icon.eot?#iefix') format('embedded-opentype'), 
    url('../iconfont/app_icon.woff') format('woff'), 
    url('../iconfont/app_icon.ttf') format('truetype'), 
}

@mixin icon-styles {
    font-family: "app_icon";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";

    @if $filename == accessories {
        $char: "\E001"
    }
    @if $filename == add-circle {
        $char: "\E002"
    }
    @if $filename == administration {
        $char: "\E003"
    }
    @if $filename == alert-triangle {
        $char: "\E004"
    }
    @if $filename == alert-triangle2 {
        $char: "\E005"
    }
    @if $filename == arrow-left {
        $char: "\E006"
    }
    @if $filename == arrow-right {
        $char: "\E007"
    }
    @if $filename == arrowleft {
        $char: "\E008"
    }
    @if $filename == arr_down {
        $char: "\E009"
    }
    @if $filename == attachment {
        $char: "\E00A"
    }
    @if $filename == attention-circle {
        $char: "\E00B"
    }
    @if $filename == back_arrow {
        $char: "\E00C"
    }
    @if $filename == bell {
        $char: "\E00D"
    }
    @if $filename == box {
        $char: "\E00E"
    }
    @if $filename == check-circle {
        $char: "\E00F"
    }
    @if $filename == check {
        $char: "\E010"
    }
    @if $filename == checklist {
        $char: "\E011"
    }
    @if $filename == check_circle {
        $char: "\E012"
    }
    @if $filename == check_medium {
        $char: "\E013"
    }
    @if $filename == check_thin {
        $char: "\E014"
    }
    @if $filename == close {
        $char: "\E015"
    }
    @if $filename == contacts {
        $char: "\E016"
    }
    @if $filename == copy {
        $char: "\E017"
    }
    @if $filename == creditcard {
        $char: "\E018"
    }
    @if $filename == dashboard {
        $char: "\E019"
    }
    @if $filename == date {
        $char: "\E01A"
    }
    @if $filename == device {
        $char: "\E01B"
    }
    @if $filename == document-faq {
        $char: "\E01C"
    }
    @if $filename == document {
        $char: "\E01D"
    }
    @if $filename == download {
        $char: "\E01E"
    }
    @if $filename == edit {
        $char: "\E01F"
    }
    @if $filename == edit2 {
        $char: "\E020"
    }
    @if $filename == ellipses-menu-vertical {
        $char: "\E021"
    }
    @if $filename == ellipses-menu {
        $char: "\E022"
    }
    @if $filename == empty-circle {
        $char: "\E023"
    }
    @if $filename == health {
        $char: "\E024"
    }
    @if $filename == help-circle {
        $char: "\E025"
    }
    @if $filename == home {
        $char: "\E026"
    }
    @if $filename == imprezz_short_icon {
        $char: "\E027"
    }
    @if $filename == info {
        $char: "\E028"
    }
    @if $filename == logout {
        $char: "\E029"
    }
    @if $filename == logout1 {
        $char: "\E02A"
    }
    @if $filename == mail {
        $char: "\E02B"
    }
    @if $filename == new-settings {
        $char: "\E02C"
    }
    @if $filename == overview {
        $char: "\E02D"
    }
    @if $filename == pdf {
        $char: "\E02E"
    }
    @if $filename == plus {
        $char: "\E02F"
    }
    @if $filename == preview {
        $char: "\E030"
    }
    @if $filename == print2 {
        $char: "\E031"
    }
    @if $filename == profile {
        $char: "\E032"
    }
    @if $filename == question-faq {
        $char: "\E033"
    }
    @if $filename == question {
        $char: "\E034"
    }
    @if $filename == radiovector {
        $char: "\E035"
    }
    @if $filename == refresh {
        $char: "\E036"
    }
    @if $filename == rollen {
        $char: "\E037"
    }
    @if $filename == save-square {
        $char: "\E038"
    }
    @if $filename == scanner {
        $char: "\E039"
    }
    @if $filename == search {
        $char: "\E03A"
    }
    @if $filename == settings {
        $char: "\E03B"
    }
    @if $filename == smarthome {
        $char: "\E03C"
    }
    @if $filename == start {
        $char: "\E03D"
    }
    @if $filename == tickets {
        $char: "\E03E"
    }
    @if $filename == trashcan {
        $char: "\E03F"
    }
    @if $filename == unlock {
        $char: "\E040"
    }
    @if $filename == upload {
        $char: "\E041"
    }
    @if $filename == user {
        $char: "\E042"
    }
    @if $filename == users {
        $char: "\E043"
    }
    @if $filename == view {
        $char: "\E044"
    }
    @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
    &:#{$insert} {
        @if $extend{
            @extend %icon;
       } @else {
        @include icon-styles;
        }
        content: icon-char($filename);
    }
}

.icon-accessories {
    @include icon(accessories)
}
.icon-add-circle {
    @include icon(add-circle)
}
.icon-administration {
    @include icon(administration)
}
.icon-alert-triangle {
    @include icon(alert-triangle)
}
.icon-alert-triangle2 {
    @include icon(alert-triangle2)
}
.icon-arrow-left {
    @include icon(arrow-left)
}
.icon-arrow-right {
    @include icon(arrow-right)
}
.icon-arrowleft {
    @include icon(arrowleft)
}
.icon-arr_down {
    @include icon(arr_down)
}
.icon-attachment {
    @include icon(attachment)
}
.icon-attention-circle {
    @include icon(attention-circle)
}
.icon-back_arrow {
    @include icon(back_arrow)
}
.icon-bell {
    @include icon(bell)
}
.icon-box {
    @include icon(box)
}
.icon-check-circle {
    @include icon(check-circle)
}
.icon-check {
    @include icon(check)
}
.icon-checklist {
    @include icon(checklist)
}
.icon-check_circle {
    @include icon(check_circle)
}
.icon-check_medium {
    @include icon(check_medium)
}
.icon-check_thin {
    @include icon(check_thin)
}
.icon-close {
    @include icon(close)
}
.icon-contacts {
    @include icon(contacts)
}
.icon-copy {
    @include icon(copy)
}
.icon-creditcard {
    @include icon(creditcard)
}
.icon-dashboard {
    @include icon(dashboard)
}
.icon-date {
    @include icon(date)
}
.icon-device {
    @include icon(device)
}
.icon-document-faq {
    @include icon(document-faq)
}
.icon-document {
    @include icon(document)
}
.icon-download {
    @include icon(download)
}
.icon-edit {
    @include icon(edit)
}
.icon-edit2 {
    @include icon(edit2)
}
.icon-ellipses-menu-vertical {
    @include icon(ellipses-menu-vertical)
}
.icon-ellipses-menu {
    @include icon(ellipses-menu)
}
.icon-empty-circle {
    @include icon(empty-circle)
}
.icon-health {
    @include icon(health)
}
.icon-help-circle {
    @include icon(help-circle)
}
.icon-home {
    @include icon(home)
}
.icon-imprezz_short_icon {
    @include icon(imprezz_short_icon)
}
.icon-info {
    @include icon(info)
}
.icon-logout {
    @include icon(logout)
}
.icon-logout1 {
    @include icon(logout1)
}
.icon-mail {
    @include icon(mail)
}
.icon-new-settings {
    @include icon(new-settings)
}
.icon-overview {
    @include icon(overview)
}
.icon-pdf {
    @include icon(pdf)
}
.icon-plus {
    @include icon(plus)
}
.icon-preview {
    @include icon(preview)
}
.icon-print2 {
    @include icon(print2)
}
.icon-profile {
    @include icon(profile)
}
.icon-question-faq {
    @include icon(question-faq)
}
.icon-question {
    @include icon(question)
}
.icon-radiovector {
    @include icon(radiovector)
}
.icon-refresh {
    @include icon(refresh)
}
.icon-rollen {
    @include icon(rollen)
}
.icon-save-square {
    @include icon(save-square)
}
.icon-scanner {
    @include icon(scanner)
}
.icon-search {
    @include icon(search)
}
.icon-settings {
    @include icon(settings)
}
.icon-smarthome {
    @include icon(smarthome)
}
.icon-start {
    @include icon(start)
}
.icon-tickets {
    @include icon(tickets)
}
.icon-trashcan {
    @include icon(trashcan)
}
.icon-unlock {
    @include icon(unlock)
}
.icon-upload {
    @include icon(upload)
}
.icon-user {
    @include icon(user)
}
.icon-users {
    @include icon(users)
}
.icon-view {
    @include icon(view)
}
@import '../../../abstracts/_variables';
@import '../../../abstracts/_mixins';

.menuItem {
  position: relative;
  color: $color-menu-text;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
  width: 45%;
  white-space: nowrap;
  @include rem(min-height, 70);
  @include rem(max-height, 70);
  @include rem(line-height, 21);
  @include rem(font-size, 14);

  .collapsed-title {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    transition: opacity 0.2s ease-in-out;
    top: 45px;
  }

  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: $color-white !important;
  }

  &::before {
    color: $color-menu-icon;
  }

  &:hover,
  &.hover {
    color: $color-white !important;
    background-color: $color-submenu;

    &::before {
      color: $color-white !important;
    }

    .collapsed-title {
      color: $color-white !important;
    }
  }

  &:before {
    top: -17px;
    bottom: 0;
    display: flex;
    align-items: baseline;
    font-weight: normal;
    transition: margin 0.2s ease-in-out;
    position: relative;

    @include rem(margin-right, 35);
    @include rem(left, 0);
    @include rem(margin-left, 60);
    @include rem(font-size, 50);

    @include breakpoint-md {
      @include rem(margin-right, 35);
    }
  }

  @include breakpoint-lg {
    @include rem(height, 82);
    @include rem(padding, 0, 20, 0, 0);
  }

  @include breakpoint-md {
    overflow: hidden;
    @include rem(width, $menu-small-width);
    @include rem(height, 82);
    @include rem(padding, 0, 0, 0, 0);

    > span {
      overflow: hidden;
    }

    &:before {
      @include rem(margin-left, 60);
      @include rem(font-size, 50);
    }
  }

  @include breakpoint-menu-sm {
    @include rem(height, 70);
    @include rem(min-height, 50);

    &::before {
      @include rem(margin-left, 60);
      @include rem(font-size, 50);
    }
  }
}

.menuItem-notFocused {
  &::before {
    color: $color-white !important;
    opacity: 0.6;
  }
}

.menuItem-active {
  background-color: $color-submenu;
  border-left-color: $color-primary;
  color: $color-white !important;
  &::before {
    color: $color-white !important;
  }
}

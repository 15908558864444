@import "../../abstracts/_variables";
@import "../../abstracts/_mixins";

$popover-width: 390px;

.topbar-popover {
	position: relative;
	width: $popover-width;

	.topbar-popover-title {
		position: relative;
		width: $popover-width;
		height: 65px;
		border-right: 1px $color-modal-footer-border solid;
		border-bottom: 1px $color-modal-footer-border solid;
		background: $color-white;
		padding-left: 20px;
		float: left;
		display: flex;
		align-items: center;
		z-index: 1;
		cursor: pointer;
		user-select: none;

		.element-title {
			width: 270px;
			margin-left: 15px;
			display: flex;
			align-items: baseline;
			pointer-events: none;

			.element-name {
				margin-right: 5px;
				max-width: 170px;
				font-weight: 600;
				font-size: 16px;
				@include truncate-text();
			}
		}

		.popover-arrow {
			position: absolute;
			top: 20px;
			right: 25px;
			color: $color-text-light;
			pointer-events: none;
			transition: all 0.3s ease-out;

			&.active {
				top: 22px;
				transform: rotate(180deg);
			}
		}
	}

	.topbar-popover-items {
		position: absolute;
		top: 65px;
		left: 0;
		width: $popover-width;
		max-height: 0;
		background: white;
		box-shadow: 0px 4px 10px 0px #ccc;
		overflow: hidden;
		transition: all 0.3s ease-out;

		&.visible {
			max-height: 450px;
		}

		.topbar-popover-item {
			position: relative;
			padding: 0 35px;
			height: 50px;
			line-height: 50px;
			font-weight: 600;
			font-size: 16px;
			@include truncate-text();
			cursor: pointer;

			&:not(:last-child) {
				&:before {
					position: absolute;
					left: 20px;
					right: 20px;
					bottom: 0;
					height: 1px;
					background: $color-modal-footer-border;
					content: " ";
					pointer-events: none;
				}
			}
		}
	}
}

@import '../../abstracts/_variables';
@import '../../abstracts/_mixins';

@mixin modalBaseStyle() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: middle;
  z-index: $z-index-modal;
  @include vendor-prefix('user-select', 'none');
}

.modal-fullscreen {
  @include modalBaseStyle();

  .modal-fullscreen-close {
    position: fixed;
    display: flex;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 3;

    @include icon(before, close2) {
      color: $color-menu-active-item;
      font-size: 24px;
    }
  }
}

.modal-base {
  @include modalBaseStyle();

  .modal-base-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background: rgba(50, 50, 50, 0.8);
    z-index: $z-index-modal + 1;
    pointer-events: none;
    @include vendor-prefix('transition', 'all 0.3s');
  }

  .modal-base-view {
    position: relative;
    height: auto;
    max-width: 1200px;
    min-width: 400px;
    padding: 25px 40px 60px;
    margin: 100px auto 20px auto;
    border-radius: 3px;
    visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    z-index: $z-index-modal + 1;
    background: $color-white;
    color: $color-text;
    @include vendor-prefix('transform', translateY(round(20%)));
    @include vendor-prefix('transition', 'all 0.3s');
    @include vendor-prefix('box-shadow', $box-shadow-modal);

    &.narrow-modal {
      min-width: 300px;
      width: 300px;
      border-radius: 7px;
    }

    .modal-base-headline {
      margin: 0;
      font-size: 32px;
      text-align: left;
      line-height: 48px;
      font-weight: bold;
    }

    .modal-base-content {
      margin: 20px 0 0;
      font-size: 14px;
      text-align: left;
    }

    .modal-base-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      padding: 0 40px 0;

      .modal-base-cancel {
        float: right;
        padding-right: 10px;
      }

      .modal-base-confirm {
        float: right;
      }
    }

    &.no-footer,
    .has-footer {
      padding-bottom: 40px;
    }

    .has-footer-big {
      padding-bottom: 70px;
    }
  }

  .modal-base-close {
    position: absolute;
    display: flex;
    top: 40px;
    right: 20px;
    cursor: pointer;
    transform: rotate(45deg);

    @include icon(before, close) {
      color: $color-white;
      font-size: 12px;
    }
  }

  &.modal-base-show {
    .modal-base-view {
      visibility: visible;
      @include vendor-prefix('transform', 'translateY(0);');
      opacity: 1;
    }

    .modal-base-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

body.has-modal {
  overflow: hidden;

  .pika-single {
    z-index: $z-index-modal + 1;
  }
}

body.fullscreen-modal {
  overflow: auto;

  &:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-bg-medium;
    z-index: $z-index-below-modal;
    content: ' ';
  }

  .app {
    display: none;
  }
}

.devices-accs-modal-component {
  .modal-base-view {
    margin: 50px auto 20px 0px !important;
  }
}

.change-detection-modal-autio .modal-base-view {
  height: 260px !important;
}

.change-detection-modal-autio .modal-base-footer {
  left: -10% !important;
}

.change-detection-modal-autio .modal-base-cancel {
  margin-left: 20px !important;
}

.change-detection-modal-autio .button-cancel {
  background: $color-primary !important;
  border-radius: 30px !important;
  color: $color-white !important;
  text-align: center !important;
}

.change-detection-modal-autio .button-primary {
  background: $color-secondary !important;
  color: #000 !important;
}

@media (min-width: $menu-breakpoint) {
  .modal-base {
    .modal-base-view {
      @include vendor-prefix(
        'transform',
        translate(round($menu-width / 2), 75px)
      );
    }

    &.modal-base-show .modal-base-view {
      @include vendor-prefix('transform', translate(round($menu-width / 2), 0));
    }
  }
}

@import '../../../abstracts/_variables';
@import '../../../abstracts/_mixins';

.submenu {
  top: 0;
  width: $submenu-width;
  height: 100vh;
  z-index: $z-index-nav-submenu;
  position: fixed;
  cursor: default;
  background-color: $color-submenu;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;

  @include rem(left, 145);

  &.submenu-invoices {
    .submenuList {
      margin-bottom: 203px;

      @include breakpoint-menu-sm {
        margin-bottom: 46px;
      }
    }
  }

  &.submenu-offers {
    .submenuList {
      margin-bottom: 130px;

      @include breakpoint-menu-sm {
        margin-bottom: -4px;
      }
    }
  }

  &.submenu-banking {
    .submenuList {
      margin-bottom: 55px;

      @include breakpoint-menu-sm {
        margin-bottom: -55px;
      }
    }
  }

  &.submenu-settings {
    .submenuList {
      margin-bottom: -44px;

      @include breakpoint-menu-sm {
        margin-bottom: -104px;
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 62px;
    left: 28px;
    font-size: 14px;
    color: $color-white;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $color-primary;
    }
  }

  &.submenu-visible {
    -webkit-animation: 0.3s slidein;
    -moz-animation: 0.3s slidein;
    -ms-animation: 0.3s slidein;
    -o-animation: 0.3s slidein;
    animation: 0.3s slidein;
  }
}

.submenuItem {
  position: relative;
  color: $color-menu-text !important;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
  width: 100%;
  white-space: nowrap;
  @include rem(min-height, 70);
  @include rem(max-height, 70);
  @include rem(line-height, 21);
  @include rem(font-size, 14);

  &:last-child {
    margin-bottom: 0;
  }

  > a {
    cursor: pointer;
    color: $submenu-text-color;

    &:hover {
      color: $color-white;
    }
  }

  &:before {
    top: -17px;
    bottom: 0;
    display: flex;
    align-items: baseline;
    font-weight: normal;
    transition: margin 0.2s ease-in-out;
    position: relative;

    @include rem(margin-right, 35);
    @include rem(left, 0);
    @include rem(font-size, 50);
    @include rem(margin-left, 75);

    @include breakpoint-md {
      @include rem(margin-right, 35);
    }
  }

  @include breakpoint-menu-sm {
    @include rem(margin, 0px 35px 24px 0px);
  }
}

.submenuItem-active {
  color: $color-white !important;
  font-weight: 600;
}

.submenuTitle {
  color: $color-white;
  margin-top: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}

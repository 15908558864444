@import "../abstracts/_variables";
@import "../abstracts/_mixins";

$size: 30px;
$width: 4px;
$background: #cccccc;
$foreground: #666666;
$duration: 1000;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes blinker {
    50% {
        background: #e6f0fc;
    }
}

@keyframes slidein {
    0% {
        width: 0%;
        opacity: 0;
    }
    100% {
        width: $submenu-width;
        opacity: 1;
    }
}

.loader {
    z-index: 10;
    color: #5f6262;
    background: rgba(255,255,255,0.9);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
	right: 0;
	
    &-opaque {
        background: transparent;
	}
	
    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
	}
	
    &_text {
        display: inline-block;
        font-size: 15px;
        margin-top: 6px;
	}
	
    &_spinner {
        position: relative;
		border: $width solid $background;
		
        &, &::after {
            box-sizing: inherit;
            border-radius: 50% !important;
            width: $size;
            height: $size;
		}

        &_lg, &_lg::after {
            height: 40px;
            width: 40px;
        }

        &_sm, &_sm::after {
            height: 20px;
            width: 20px;
        }
		
        &::after {
            content: "";
            position: absolute;
            top: -$width;
            left: -$width;
            top: -$width;
            border: $width solid $foreground;
            border-right-color: transparent;
            border-top-color: transparent;
            animation: spin #{$duration}ms linear infinite;
        }
    }
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

html, * {
    box-sizing: border-box;
}

body {
    background: $color-bg-medium;
    color: $color-text;
}

.critical_logo {
    width: 136px;
    height: 41px;
    margin-bottom: 30px;
    fill: black;
}

.app-version {
    display: none;
}

.freshwidget-theme {
    width: 117px;
    text-align: center;
}
.freshwidget-theme:hover {
    background-color: #194B7C!important;
}

.freshwidget-button {
    top: unset!important;
    bottom: 171px!important;
}
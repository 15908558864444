@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.customer-edit-component-wrapper {
  .customer-edit-type-toggle {
    padding: 0px 7.5px 8px;

    .radio_label {
      position: relative;
      top: -2px;
      margin: 0 20px 0 10px;
      font-size: 15px;
    }
  }

  .selectInput_label {
    margin-bottom: -5px;
  }

  .customer-edit-kind-toggle {
    width: 230px;
    padding: 4px 7.5px 0;
  }

  .customer-edit-person-details {
    margin-top: 30px;
  }

  .customer-edit-city-select {
    margin-top: 12px;
  }

  .customer-edit-country-select {
    margin-top: 30px;
    padding-right: 6px;
  }

  .customer-edit-address-street {
    padding: 20px;
  }

  .customer-edit-paycondition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;

    label {
      width: 51%;
      font-size: 16px;
      color: $color-text-muted;
      letter-spacing: 0.025em;
      line-height: 39px;
    }

    .selectInput {
      width: 46%;

      .Select-control {
        border: 1px solid #ddd;
        height: 39px;

        .Select-value {
          line-height: 39px;
          padding-left: 10px !important;
        }
      }
    }
  }

  .ql-blank:before {
    left: 0 !important;
    right: 0 !important;
  }

  .customer-edit-notes-alert {
    position: relative;
    padding: 5px 0 10px;

    .notes-alert-label {
      font-size: 14px;
      color: $color-text-muted;
    }

    .customer-edit-notes-alert-toggle {
      width: 90px;
      top: 0;
    }
  }

  .customer-edit-contactpersons {
    margin-bottom: 25px;
    word-break: break-all;
  }

  .customer-edit-contactperson-row {
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid $color-line-light;

    .label {
      font-weight: 600;
    }

    .customer-edit-contactperson-controls {
      display: none;
      position: absolute;
      right: 0;
      top: 50%;
      @include vendor-prefix('transform', 'translateY(-50%)');

      .icon {
        margin: 5px 0;
        cursor: pointer;
        @include vendor-prefix('user-select', 'none');

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &.customer-edit-contactperson-row-first {
      border-top: 1px solid $color-line-light;
    }

    &:hover {
      .customer-edit-contactperson-controls {
        display: block;
      }
    }
  }
  .installation-details {
    label {
      font-weight: 700;
      min-width: 60px;
      margin-right: 8px;
    }
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    span {
      color: #60626e;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text-blue {
      color: #0c85ff;
    }
  }

  .site-detail {
    display: flex;
    flex-direction: column;
    label {
      font-weight: 700;
      .salutation {
        color: #60626e;
        font-weight: normal;
        margin-right: 5px;
      }
    }
    span {
      color: #60626e;
    }
    .text-orange {
      color: #dd8927;
    }
    .text-blue {
      color: #0c85ff;
    }
  }
  .ticket-edit-subsequentWorksNecessary {
    position: relative;
    .notes-alert-label {
      font-size: 14px;
      color: $color-text-muted;
    }

    .customer-edit-notes-alert-toggle {
      position: absolute;
      width: 40px;
      top: 0;
      right: 0;
    }
  }
  table {
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
  }
  .tbl-contactperson {
    text-align: left;
    border-collapse: collapse;

    th {
      padding-bottom: 20px;
      font-size: 14px;
    }
    td,
    th {
      padding: 25px 5px;
      padding-left: 20px;
    }

    tbody {
      tr {
        td {
          strong,
          span {
            display: block;
          }
          strong {
            font-size: 16px;
            font-weight: 700;
          }
          span {
            font-size: 14px;
            color: #60626e;
            font-weight: 600;
          }
        }
      }
      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
    }
  }
  .iconcontact {
    float: left;
    margin-right: 10px;
  }

  .ticket-edit-type-toggle {
    display: grid;
    margin-top: 0px;
  }
  .mt-5minu {
    margin-top: -5px !important;
  }
}

.audito-notes-edit {
  padding: 0px 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.audito-mt5-edit {
  margin-top: 5px;
}

.select-search {
  position: relative;
  input {
    border: none;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 0 !important;
    box-shadow: none;
    display: block;
    color: #272d30;
    padding: 0;
    width: 100%;
    letter-spacing: inherit;
    padding-top: 0px;
    height: 40px;
    outline: none;
  }
  .select-search__select {
    position: absolute;
    z-index: 99999;
    width: 100%;
    ul {
      list-style-type: none;
      background-color: $color-white;
      border-radius: 0 0 5px 5px;
      border-top: none;
      max-height: 180px;
      margin: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
      width: 100%;
      border: 1px solid #ccc;
      li {
        padding: 7px 10px;
        cursor: pointer;
        color: #6e6e6e;
        &:hover {
          background: #d5f3ff;
        }
        button {
          background: transparent;
          border: none;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.cust-radio-s .radio-custom-wrapper {
  display: flex;
  align-items: center;
}

.cust-radio-s .radio-custom-label-wrapper {
  padding-left: 8px;
}

.cust-radio-s input[type='radio'].radio-custom + span.radio-custom-circle {
  border: 1px solid #bbbbbb !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt23 {
  margin-top: 23px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.cust-radio-s
  input[type='radio'].radio-custom:checked
  + span.radio-custom-circle {
  border: 1px solid $color-secondary !important;
  background: none !important;
}

.cust-radio-s
  input[type='radio'].radio-custom:checked
  + span.radio-custom-circle::after {
  background: $color-secondary !important;
}

.react-datepicker-wrapper {
  width: 100%;
  input {
    border: none;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 0 !important;
    box-shadow: none;
    display: block;
    color: #272d30;
    padding: 0;
    width: 100%;
    letter-spacing: inherit;
    padding-top: 0px;
    height: 30px;
    outline: none;

    &:focus-visible {
      outline: none;
    }
  }
  .react-datepicker-popper {
    width: 300px;
  }
}

.edit-contact-person-names {
  .form_input {
    margin-top: 0px;
  }
}
.edit-contact-person-email-input {
  .form_input {
    margin-top: 0px;
  }
}
.fixed-btn-width {
  width: 252px;
}
.switch-margin {
  margin-top: 35px;
  .edit-contact-person-maincontact {
    align-items: center;
    display: flex;
  }
}

.patient-edit-label {
  font-size: 13px;
  color: #232c45;
  font-weight: 600;
}

.patient-edit-errorMessage {
  color: #fa6e6e;
  white-space: normal;
  word-wrap: break-word;
  font-size: 12px;
  min-height: 18px;
}

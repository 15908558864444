@import "../../abstracts/_variables";
@import "../../abstracts/_mixins";

.button-component-wrapper {

	padding-top: 10px;

	button {
		min-width: 140px;
	}

	.button-rounded {
		height: 38px;
		line-height: 38px;
		padding: 0 40px;
		border-radius: 30px;
		font-size: 14px;
	}

	.button-inverted-no-padding {
		padding: 0;
		border-radius: 0;
		color: $color-primary;
		background: transparent;

		&:hover,
		&:focus {
			color: $color-text-muted;
			background: transparent;
		}

		&:active  {
			color: $color-primary;
			background: transparent;
		}
	}

	.icon {
		float: left;
		margin-right: 10px;

		&.loader_spinner {
			margin-top: 11px;
			width: 16px;
			height: 16px;

			&:after {
				width: 16px;
				height: 16px;
			}
		}
	}

	.text-content {
		height: 100%;
		flex-direction: column;
		line-height: 1.2;
		display: flex;
		justify-content: center;

		.sub-label {
			font-size: 12px;
			font-weight: 500;
		}
	}

	.button-rounded {
		height: 38px;
		line-height: 38px;
		padding: 0 40px;
		border-radius: 30px;
		font-size: 14px;
	}

	.button-cancel {
		color: $color-text;
		background: transparent;
		font-weight: normal;
		text-align: left;

		&:hover {
			color: $color-text;
			background: transparent;
		}
	}

	.button-text {
		color: $color-text;
		background: none;
		padding: 0 10px;

		&-green {
			color: $color-success;
			background: none;

			&:hover,
			&:link,
			&:visited,
			&:focus,
			&:active,
			&:disabled {
				cursor: auto;
				opacity: 1;
				color: $color-success;
			}

			& .icon-check-circle {
				color: $color-success !important;
				margin-top: -10px;
			}
			& .text-content {
				margin-left: 22px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:hover,
		&:link,
		&:visited,
		&:focus,
		&:active,
		&:disabled {
			color: $color-text;
			background: none !important;
		}

		.icon {
			float: none;
			display: inline-block;
		}
	}

	.button-primary-alternative {
		color: $color-primary;
		background: $color-white;
		font-size: 20px;

		&:not(:disabled):hover,
		&:not(:disabled):focus  {
			background: $color-primary-superlight;
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-primary;
		}
	}

	.button-danger {
		color: $color-white;
		background: #fb6f72;

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: #f92e33;
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-white;
		}
	}

	.button-google {
		background: $color-white;
		border: 1px solid #DB4C3F;
		color:#DB4C3F;
		padding: 0 25px;
		width: 140px !important;
		line-height: 34px;

		.icon {
			font-size: 18px;
			line-height: 36px;
			margin-right: 0 !important;
		}

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: #DB4C3F;
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-white;
		}
	}

	.button-apple {
		background: $color-white;
		border: 1px solid #000000;
		color: #000000;
		padding: 0 25px;
		width: 140px !important;
		line-height: 34px;

		.icon {
			font-size: 18px;
			line-height: 36px;
			margin-right: 0 !important;
		}

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: #000000;
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-white;
		}
	}

	.button-green {
		color: $color-white;
		background: $color-success;

		& .icon-check-circle {
			color: $color-white !important;
		}
		& .text-content {
			margin-left: 22px;
		}

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: darken($color-success, 5%);
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-white;
		}
	}

	.button-grey {
		color: $color-white;
		background: $badge-color;

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: darken($badge-color, 5%);
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-white;
		}
	}

	.button-white {
		color: $color-primary;
		background: $color-white;

		&:not(:disabled):hover,
		&:not(:disabled):focus {
			background: darken($color-white, 5%);
		}

		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: $color-primary;
		}
	}

	.button:disabled,
	.button.disabled {
		background: $color-button-disabled;
		color: $color-button-text-disabled;
	}

	.button.disabled {
		cursor: default;
	}
}

@import '../../../abstracts/_mixins';
@import '../../../abstracts/_variables';

.edit-contact-person-modal-component {
  .modal-base-content {
    margin-top: 0 !important;
    overflow: visible !important;
  }

  .map-view {
    .unbox {
      padding-left: 0px;
    }

    .map-view-modal {
      height: 400px;

      .leaflet-container {
        width: 100%;
        height: 100%;
      }
      .leaflet-bottom {
        display: none;
      }
    }
  }

  .edit-contact-person-modal-content {
    margin-top: 10px;

    .edit-contact-person-names {
      margin-top: -12px;
    }

    .add-variant-color {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .variant-color {
        background: #edeff3;
        border-radius: 8px;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-right: 10px;
        div {
          width: 30px;
          height: 30px;
          margin: 10px;
          border-radius: 8px;
        }
      }

      .variant-color-detail {
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #232c45;
          margin: 0px;
          padding: 0px;
        }
      }
    }

    .acc-sub-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #40444e;
      margin: 0px;
    }

    .square-color-box {
      width: 60px;
      height: 60px;
      border-radius: 8px;
    }
  }

  .edit-contact-person-subheadline {
    margin-bottom: 18px;
  }

  .edit-contact-person-position-select {
    margin: 12px 0 3px;
  }

  .input_error.select_error {
    position: relative;
    bottom: 0px;
    height: 0px;
    top: -6px;
  }

  .input_error {
    position: absolute;
    bottom: 0;
    height: 0;
    top: auto;
  }
  .selectInputError {
    position: relative;
    top: auto;
  }

  .dateInput_label {
    color: $color-text;
  }

  .dateInput_input {
    border-color: $color-input-bar;
    height: 29px;
    margin-bottom: 42px;
  }

  .edit-contact-person-maincontact {
    .checkbox_label {
      font-size: 15px;
      color: $color-text-muted;
    }
  }
}

.hno-select-modal {
  height: 600px;
  max-width: 750px;
  margin-inline: auto;
  position: relative;
}

.hno-select-modal .list-advanced-component {
  padding-top: 60px;
}

.select-hno-list {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-left: 0;
}

.select-hno-list .list-advanced-head {
  top: 0;
  padding-top: 0px;
}

@import "../../../abstracts/_variables";
@import "../../../abstracts/_mixins";

.menuBar {
	height: 100%;

	@include breakpoint-lg {
		@include rem(width, $menu-width);
	}

	@include breakpoint-md {
		width: $menu-small-width;
	}
}

.menuBar_container {
	display: flex;
	position: absolute;
	align-items: flex-start;
	@include rem(top, 115);
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin collapsed-menu-item {
	&::before {
		@include rem(margin-left, 60);
		@include rem(font-size, 50);
	}

	.collapsed-title {
		opacity: 1;
		color: $menu-text-color;
	}

	&.menuItem-active {
		.collapsed-title {
			color: $color-white;
		} &::before {
			color: $color-white !important;
		}	
	}
}

.menuBar_content {
	width: 100%;
	margin-top: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100% - 120px);
	scrollbar-width: thin;
	-webkit-scrollbar-width: thin;
	-ms-scrollbar-width: thin;

	&.submenu-visible {
		.menuItem:not(.menuItem-active) {
			@include breakpoint-lg() {
				&::before {
					color: $menu-text-color !important;
					opacity: .6;
				}	
			}

			&:hover {
				color: $color-white !important;

				&::before {
					color: $color-white !important;
				}
			}
		}

		@include breakpoint-menu-lg() {
			.menuItem {
				@include collapsed-menu-item();
			}		
		}
	}

	@include breakpoint-md-menu-sm {
		.menuItem {
			@include collapsed-menu-item();

			.collapsed-title {
				color: $color-menu-icon;
			}
		}
	}
}
.menuBar_content::-webkit-scrollbar {
    width: 5px;
}
.menuBar_content::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 6px;
}

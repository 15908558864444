@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.forgot-password-wrapper {
	.landing-sidebar {
		.forgot-password-fact-icon svg {
			position: absolute;
			top: 38px;
			left: 50%;
			@include vendor-prefix('transform', 'translateX(-50%)');
			height: 100px;
			color: $color-white;
		}
	}
}

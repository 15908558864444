@import '../../../abstracts/_variables';
@import '../../../abstracts/_mixins';

.menuFooter {
  position: absolute;
  border: 0;

  &:hover {
    background-color: $color-menu-background;
  }

  .menuItem {
    border: 0px;

    &::before {
      color: $color-menu-icon;
    }
  }

  @include breakpoint-lg {
    left: 0;
    right: 0;
    @include rem(bottom, 10);
  }

  @include breakpoint-md {
    width: 100%;
    left: 0;
    right: 0;
    @include rem(bottom, 10);
  }
}

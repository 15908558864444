@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.detail-view-head-container {
  position: relative;
  width: 100%;
  height: 630px;
  padding-top: 60px;

  .detail-head-copy-link-popover {
    display: flex;

    .detail-head-copy-link-content {
      width: 195px;
      margin-right: 10px;
      border: 0 none;
      border-bottom: 1px solid $color-line-light;
    }

    .icon {
      margin-left: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: $color-text-placeholder;
      color: $color-white;

      &:hover {
        background: #898989;
      }
    }
  }
}

.align-center {
  align-items: center;
}

.box-showdow-carousel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.detail-content-view-document {
  position: relative;
  width: 925px;
  height: 100%;
  margin: -305px auto 0;
  background: $color-bg-medium;
  border: 1px solid $color-line-light;
  @include vendor-prefix('box-shadow', $box-shadow-subscription-box);

  .detail-view-badge {
    position: absolute;
    top: -17px;
    left: 50%;
    @include vendor-prefix('transform', 'translateX(-50%)');
  }
}

.detail-view-document {
  position: relative;
  width: 925px;
  height: auto;
  margin: -445px auto 0;
  background: $color-bg-medium;
  border: 1px solid $color-line-light;
  @include vendor-prefix('box-shadow', $box-shadow-subscription-box);

  .detail-view-badge {
    position: absolute;
    top: -17px;
    left: 50%;
    @include vendor-prefix('transform', 'translateX(-50%)');
    z-index: 3;
  }

  img:first-of-type {
    margin-top: 0;
    border: 1px solid $color-line-light;
  }

  .detail-view-preview {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  #ticket-detail-pdf-wrapper {
    width: 925px;
    z-index: 2;
    position: relative;
    margin-bottom: -4px;
  }

  canvas {
    margin-top: 50px;
    background: $color-bg-medium;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .offer-image-draft-state-hint {
    width: 100%;
    height: auto;
    margin-top: 50px;
    text-align: center;
    padding: 140px 80px;
    font-size: 18px;
    background: $color-white;
    border: 1px solid $color-line-light;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.detail-view-box {
  position: relative;
  width: 925px;
  margin: 20px auto;
  padding: 40px;
  background: $color-white;
  border: 1px solid $color-line-light;
  @include vendor-prefix('box-shadow', $box-shadow-subscription-box);
}

.detail-view-badge {
  height: 34px;
  line-height: 32px;
  padding: 0 30px;
  background: $color-white;
  border-radius: 17px;
  border: 1px solid $color-line-light;
  color: $color-text-light;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 2px;
  @include vendor-prefix('user-select', 'none');

  &.detail-view-badge-rejected {
    color: $color-white !important;
    background: $badge-color-alert !important;
    border: 0 none !important;
  }

  &.detail-view-badge-accepted {
    background: $badge-color-light !important;
    color: $color-blue-light !important;
    border: 0 none !important;
  }

  &.detail-view-badge-expensed {
    color: $color-white !important;
    background: $badge-color-success !important;
    border: 0 none !important;
  }

  &.detail-view-badge-invoiced {
    color: $color-white !important;
    background: $badge-color-success !important;
    border: 0 none !important;
  }

  &.detail-view-badge-overdue {
    color: $color-white !important;
    background: $badge-color-alert !important;
  }

  &.detail-view-badge-draft {
    background: $color-white !important;
    color: $color-text-light !important;
    border: 1px solid $color-line-light !important;
  }

  &.detail-view-badge-paid {
    color: $color-white !important;
    background: $badge-color-success !important;
  }

  &.detail-view-badge-cancelled {
    color: $color-text-placeholder !important;
    background: $color-dot-disabled !important;
  }

  .icon {
    margin-right: 7px;
  }

  .detail-view-badge-text {
    display: inline-block;
    text-transform: uppercase;
  }
}

@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.collapsible {
  cursor: pointer;
  border: solid 1px #f2f2f2;
  padding: 15px;
  background-color: #EDEFF3;
  color: #000;
  border-radius: 8px;
  }

  .colcontent {
	cursor: pointer;
	padding: 15px;
	font-size: 14px;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
}

.checkbox > input {
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #000;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: $color-white;
    cursor: pointer;
  }

.checkbox > input:checked {
    border: 1px solid #000;
    background-color: $color-secondary;
}

.checkbox > input:checked::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: $color-white;
    position: absolute;
    left: 0.4rem;
    top: 0rem;
}

.checkbox > input:active {
    border: 2px solid #34495E;
}

.tblrolepermission
{
  table-layout: fixed;
		max-width: 100%;
		width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    td:nth-child(1) {
      width: 50%;
  }
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right:20px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
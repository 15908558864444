@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.landing-wrapper.login-wrapper {
	.landing-email-input-wrapper {

		.text-input-component  {
			margin-bottom: 20px;
		}

		.button-between-label {
			color: #ccc;
		}

		.google-error {
			margin-bottom: 20px;
			font-size: 13px;
			color: $color-error;
		}
	}

	.landing-content {
		.landing-content-inner {
			.auric-logo {
				position: relative;
				left: 36%;
				bottom: 10px;
				width: 200px;
				height: 56px;
			}
			.button {
				width: 300px;
				margin: 18px 0;
			}
		}
	}

	.landing-sidebar {
		.login-fact-icon svg {
			position: absolute;
			top: 38px;
			left: 50%;
			@include vendor-prefix('transform', 'translateX(-50%)');
			height: 100px;
			color: $color-white;
		}
	}

	.landing-content-footer {
		display: flex;
   	 	justify-content: center;

		.left-link {
			margin-right: 40px;
		}
	}
}

.login-imprint-footer {
    position: fixed;
    bottom: 10px;
	left: 20px;

	a {
		margin-right: 25px;
		font-size: 14px;
		color: #99a0aa!important;
	}
}

.mobile-text-content {
	margin: -5px 0 10px 0;
	font-size: 13px;
	line-height: 22px;
}
.placeholder-image {
  width: 50px;
  aspect-ratio: 1/ 1;
  margin-bottom: 20px;
}

.placeholder-text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #132e48;
  text-align: center;
  width: 95%;
}

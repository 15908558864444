@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.customer-detail-wrapper {
  .pagebox_content-divided {
    .buttons {
      display: flex;

      .button-component-wrapper:first-of-type {
        margin-right: 10px;
      }
    }
    .text-blue {
      color: #0c85ff;
    }
  }
  .custom-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .contactPersonsCarousels {
    min-height: 410px;
    .react-multiple-carousel__arrow--left {
      left: -10px;
      background-color: transparent;
    }
    .react-multiple-carousel__arrow--right {
      right: -10px;
      background-color: transparent;
    }
    .react-multiple-carousel__arrow {
      &:before {
        color: #333;
      }
    }
    .mlt-cont {
      margin-top: 16px;

      li {
        cursor: pointer;
        display: inline-block;
        border: 3px solid #d2d2d2;
        color: #d2d2d2;
        font-weight: 600;
        text-align: center;
        border-radius: 50px;
        font-size: 11px;
        height: 40px;
        line-height: 35.5px;
        margin-bottom: 8px;
        margin-right: 10px;
        width: 40px;
      }

      .active {
        border: 3px solid $color-primary-light;
        color: $color-primary-light;
      }
    }
  }

  .ch_head {
    margin-bottom: 0;
  }

  .cp_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 114px;

    &-small {
      justify-content: flex-start;
      margin-right: 17px;
    }
  }

  .cp_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $color-primary-light;
    border-radius: 50%;
    color: $color-primary-light;
    height: 114px;
    width: 114px;
    margin: 0px;
    font-size: 42px;

    &-small {
      font-size: 24px;
      height: 70px;
      width: 70px;
    }
  }

  .cp_text_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    width: 100%;

    &-small {
      height: 114px;

      & > :nth-child(2) {
        margin-top: 0 !important;
      }
    }

    & > :nth-child(2) {
      margin-top: 40px;
    }
  }

  .cp_sbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  // -----------------------------
  // detail view graph
  // -----------------------------
  .graph-cont {
    margin-top: 50px;

    .chart {
      height: 280px;
      margin-bottom: 62px;
    }
  }

  // -----------------------------
  // detail view map
  // -----------------------------
  .address-map {
    background: url('../../../../assets/images/maps_bg.jpg') no-repeat center
      center !important;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 320px;
  }

  .mapInfo {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 360px;
    margin-right: 48px;
    padding: 44px 60px 36px;

    &_heading {
      line-height: 28px;
      margin-bottom: 8px;
    }

    &_subheading {
      font-size: 15px;
      color: $color-text-muted;
    }

    &_buttons {
      margin-top: 36px;
    }

    .btn-blue-icon {
      display: flex;
      background: #2879b3;
      align-items: center;

      &:before {
        font-size: 22px;
        margin-top: -10px;
      }
    }
  }

  //table contact Person
  table {
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
  }
  .tbl-contactperson {
    text-align: center;
    border-collapse: collapse;

    th {
      padding-bottom: 20px;
      font-size: 14px;
    }
    td,
    th {
      padding: 25px 5px;
    }

    tbody {
      tr {
        td {
          strong,
          span {
            display: block;
          }
          strong {
            font-size: 16px;
            font-weight: 700;
          }
          span {
            font-size: 14px;
            color: #60626e;
            font-weight: 600;
          }
        }
      }
      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
    }
  }

  // Installation
  table {
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
  }
  .tbl-installation {
    text-align: center;
    margin-top: 50px;
    border-collapse: collapse;

    th {
      padding-bottom: 20px;
      font-size: 14px;
    }
    td,
    th {
      padding: 25px 5px;
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #000000;
          strong,
          span {
            display: block;
          }
          strong {
            font-size: 16px;
            font-weight: 700;
          }
          span {
            font-size: 14px;
            color: #60626e;
            font-weight: 600;
          }
        }
      }
    }
  }

  // -----------------------------
  // edit view contact person list
  // -----------------------------
  .contactPerson {
    &_item {
      & .contactPerson_column {
        min-height: 80px;
      }

      &:hover {
        button.button {
          display: inline-block !important;
        }
      }

      &:last-child {
        margin-bottom: 20px;

        & .contactPerson_column {
          border-bottom: 1px solid #cccccc;
        }
      }

      .contact-communication {
        .row:first-child {
          margin-top: 0 !important;
        }

        .row:last-child {
          margin-bottom: 0 !important;
        }

        .commValue {
          word-wrap: break-word;
        }
      }
    }
  }

  // -----------------------------
  // Customer Notes
  // -----------------------------
  .customerNotes {
    .htmlInput_controls {
      bottom: 0;
    }
  }

  // -----------------------------
  // Customer History
  // -----------------------------
  .customerHistory {
    &_container {
      display: block !important;
    }

    &_list {
      width: 100%;

      .list_row {
        padding-left: 5px;
        padding-right: 5px;

        &-canceled {
          .list_item:nth-child(4) {
            padding-right: 0px;
          }
        }
      }

      .list_info {
        padding-left: 5px;
        padding-right: 6px;
      }
    }
  }

  .selectize-full-width {
    .selectize-dropdown {
      width: 480px !important;
    }
  }

  .selectInput_label {
    width: 50%;
  }

  .customer-history-list-head-content {
    position: relative;
    width: 100%;
    padding: 10px 0;
  }

  .list-component {
    .list-table {
      .list-table-cell {
        padding: 25px 0 !important;
      }
    }
  }

  .customer-history-list-pagination {
    text-align: center;
    padding: 50px 0 0 0;
  }

  .customerHistory_container {
    min-height: 400px;
  }

  .customer-history-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error-headline {
      margin-bottom: 20px;

      h1 {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .companyNameDisplay {
    color: #000000;
    font-weight: 700;
    font-size: 30px;
  }

  .notes .row > div:last-child {
    padding-left: 10px;
    border-top: 1px solid $color-input-bar;
  }

  .box-gradient .htmlInput_input {
    border: 1px solid transparent;
    border-image: linear-gradient(to top, #7c7c7c, white);
    border-image-slice: 1;
    color: #666;
  }
  .consultation-user-info {
    margin-top: 140px;
    background: #f7f8fa;
    border-radius: 16px;
    padding: 15px;
    align-items: center;

    .consultation-user-profile-warapper {
      background: $color-secondary;
      border-radius: 24px;
      width: 70px;
      align-items: center;
      text-align: center;

      p {
        width: 100%;
        height: 100%;
        justify-content: center;
        padding-top: 15%;
        font-size: 39px;
        line-height: 58px;
        color: $color-white;
        font-weight: bold;
      }
    }

    .consultation-user-left {
      border-right: 0.5px solid #aeb5c7;
      padding-right: 15px;

      li {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #40444e;
        padding: 2px 0px;
      }
    }

    .consultation-user-right {
      padding-left: 15px;

      li {
        color: #40444e;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        padding: 2px 0px;
      }
    }
  }

  .comment-history {
    .comment-history-title {
      margin: 0px;
      padding: 0px;

      h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #40444e;
        margin-top: 30px;
      }
    }

    .comment-history-content {
      .row {
        align-items: flex-end;
        .col-md-8 {
          p {
            color: #70737a;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
        .comment-history-date {
          display: flex;
          gap: 4px;
          justify-content: flex-end;
          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: normal;
            color: #70737a;
          }
        }
      }
    }
  }
}

.audito-insurancenumber {
  color: rgb(92, 97, 112);
  word-wrap: break-word;
  display: inline-block;
  width: 70%;
}

.audito-imageFit {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.consultation-detail-wrapper {
  .wrapper-has-topbar-with-margin {
    margin-top: 40px;
  }

  .copy-icon {
    margin-top: -5px;
    margin-left: 10px;
    cursor: pointer;

    &::before {
      font-size: 25px;
    }
  }

  .text-tbl-bold {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px;
    color: #40444e !important;

    &:before {
      font-size: 22px;
      font-weight: 600;
      padding-right: 8px;
    }
  }

  .icon-edit {
    &:before {
      font-size: 14px !important;
    }
  }

  .consultation-popover {
    &::before {
      font-size: 14px;
      padding-right: 8px;
    }

    .popover-entry-label {
      font-size: 14px;
      line-height: 18px;
      color: #40444e;
    }
  }

  .consultation-tabs > div {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    position: relative;
    .arrow-shape {
      padding: 0px 20px;
      flex: 1;
      clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0% 0%);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      & > p {
        z-index: 99;
        font-weight: 600;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }

      &.locked {
        background-color: $color-success;
        color: $color-white;
        &::before {
          content: '';
          background-color: $color-success;
          position: absolute;
          width: 100%;
          height: 100%;
          clip-path: polygon(90% 2%, 100% 50%, 90% 97%, 0% 97%, 5% 50%, 0% 2%);
        }
      }

      &.active {
        background-color: #df830b;
        color: $color-primary;
        &::before {
          content: '';
          background-color: #df830b;
          position: absolute;
          width: 100%;
          height: 100%;
          clip-path: polygon(90% 2%, 100% 50%, 90% 97%, 0% 97%, 5% 50%, 0% 2%);
        }
      }
      &.completed {
        background-color: #fff1e1;
        color: #ff8b00;
        &::before {
          content: '';
          background-color: #fff1e1;
          position: absolute;
          width: 100%;
          height: 100%;
          clip-path: polygon(90% 2%, 100% 50%, 90% 97%, 0% 97%, 5% 50%, 0% 2%);
        }
      }
      &.incomplete {
        opacity: 50%;
        color: $color-menu-background;
        cursor: auto;
        &::before {
          content: '';
          background-color: #17365231;
          position: absolute;
          width: 100%;
          height: 100%;
          clip-path: polygon(90% 2%, 100% 50%, 90% 97%, 0% 97%, 5% 50%, 0% 2%);
        }
      }
    }

    li.active {
      background: $color-secondary;
      padding: 10px 30px;
      border-radius: 8px;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -15px;
        bottom: 0%;
        width: 0;
        height: 0;
        padding-top: 1px;
        border-bottom: 19px solid $color-secondary;
        border-top: 17px solid $color-secondary;
        border-left: 14px solid $color-secondary;
        border-right: 8px solid transparent;
        border-radius: 5px;
        transform: rotate(180deg);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        right: -23px;
        bottom: 25%;
        width: 0;
        height: 0;
        border-bottom: 18px solid $color-secondary;
        border-top: 0px solid black;
        border-left: 15px solid transparent;
        border-right: 18px solid transparent;
        transform: rotate(90deg);
      }
    }
  }

  .consultation-links {
    display: flex;
    justify-content: space-between;

    button {
      background: $color-white;
      border-radius: 30px;
      border: 1px solid #142d46;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      &.active {
        border: none;
        background-color: $color-success;
        color: $color-white;
      }
      p {
        margin: 0 10px;
      }
      & .icon-device {
        font-size: 25px;
      }
      span {
        color: $color-menu-background;
      }
    }

    .consultation-left-btn {
      div {
        margin-top: -8px;

        &::before {
          color: #223950;
          font-size: 28px;
        }
      }
    }

    .btn-consultation-a {
      min-width: 130px !important;
      height: 40px !important;
      padding: 0 0px !important;
    }

    .consultation-left-right-btn {
      div {
        margin-top: -8px;

        &::before {
          color: #223950;
          font-size: 28px;
        }
      }
    }

    .consultation-right-btn {
      div {
        float: right;
        margin-left: 10px;
        margin-top: -8px;

        &::before {
          color: #223950;
          font-size: 28px;
        }
      }
    }
  }

  .button-primary:not(:disabled):hover,
  .button-primary:not(:disabled):focus {
    background: $color-white;
  }

  .consultation-error {
    background: rgba(255, 241, 225, 0.5);
    border: 1px solid $color-secondary;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;

    .icon {
      color: #d98c03;
      &::before {
        font-size: 35px;
        padding-left: 10px;
        margin-right: -10px;
      }
    }

    p {
      color: #d98c03;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 0px 20px;
    }
  }
}

.cus-dashboard-list {
  .ag-root-wrapper-body {
    padding-bottom: 50px !important;
  }
  .list-advanced-pagination-dropdown {
    background: $color-white !important;
  }
  #ag-10 {
    z-index: 99;
    position: fixed;
    bottom: 0;
    right: 30px;
    height: 43px;
    width: 65%;
  }
}

.audtio-gray-label {
  color: #40444e;
}

.audtio-red-label {
  color: #ae0e16;
}

.audtio-green-label {
  color: $color-success;
}

.cus-dashboard-list .ag-paging-panel {
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 30px;
  height: 43px;
  width: 65%;
}
.popover-entry.consultation-popover.icon.icon-preview::before {
  font-size: 28px;
}
.popover-entry-icon.icon.icon-preview {
  font-size: 32px;
  height: 30px;
  margin-top: -20px;
}
.popover-entry-icon.icon-refresh {
  font-size: 1.8rem;
  line-height: 0.15rem;
  transform: translateY(-3px);
}

.add-document-btn {
  background-color: $color-secondary !important;
}

.product-section {
  background-color: #f7f8fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: 12px;

  & > p {
    color: #70737a;
    font-size: 16px;
  }
  & .button-component-wrapper {
    .edit {
      height: 28px !important;
    }
  }
}

.step-accessories {
  .step-accessories-title {
    margin: 0px;
    font-size: 25px;
    margin-top: -10px;
    i {
      font-size: 18px;
      cursor: pointer;
      margin-right: 0.6rem;
    }
  }

  .step-accessories-left {
    .acc-pro-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #40444e;
    }

    .acc-prod-content {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f7f8fa;
      padding-bottom: 5px;
      margin-bottom: 10px;
      width: 95%;

      &:hover {
        cursor: pointer;
      }

      .acc-prod-content-img {
        border: 0.5px solid #aeb5c7;
        box-sizing: border-box;
        border-radius: 5px;
        width: 72px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          padding: 5px;
          object-fit: contain;
        }
      }

      p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #40444e;
        width: auto;
        padding-left: 10px;
      }
    }

    .acc-prod-content-active {
      border: 1px solid $color-secondary;
      border-radius: 5px;
      padding: 4px;
      background-color: rgba($color-secondary, 0.25);
      font-weight: 600;
    }

    .audito-acc-step-accessories-left {
      max-height: 200px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f7f8fa;
        border-radius: 500px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #aeb5c7;
        border-radius: 500px;
      }

      .acc-prod-content {
        margin-bottom: 0px !important;
        .acc-prod-content-img {
          width: 60px;
          height: 40px;
        }
        p {
          font-size: 12px;
        }
      }

      .acc-prod-content-active {
        margin-bottom: 4px !important;
      }
    }

    .acc-pro-search {
      margin: 20px 0px;
      width: 100%;
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        top: 0px;
        z-index: 9999;
      }

      input {
        border: none;
        border-bottom: 1px solid #f7f8fa;
        padding: 0 25px;
        padding-bottom: 10px;
        position: relative;
      }
    }

    .acc-pro-toggle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      h6 {
        margin: 0px;
      }
      span {
        padding-top: 5px;
        margin-left: 5px;
      }
    }

    .acc-pro-checkboxes {
      height: 140px;
      overflow-x: auto;
      margin-bottom: 20px;

      .checkbox {
        align-items: center;

        span {
          padding-left: 10px;
        }
      }
    }
  }

  .acc-product-content {
    height: 450px;
    overflow-y: auto;
    padding: 0.5rem;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f7f8fa;
      border-radius: 500px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aeb5c7;
      border-radius: 500px;
    }

    .pro-card-acc {
      border: 1px solid #f7f8fa;
      box-sizing: border-box;
      box-shadow: 8px 8px 32px rgba(174, 181, 199, 0.2);
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 10px;
      position: relative;
      height: 300px;

      .pro-card-acc-img {
        height: 150px;
        padding: 20px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          image-rendering: -webkit-optimize-contrast;
        }
      }

      .pro-card-acc-content {
        padding: 10px;
        width: 100%;

        .pro-card-acc-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .pro-card-acc-titles {
            flex: 1;
            p {
              margin: 0;
              color: #40444e;
              font-size: 12px;
              font-weight: 600;
              line-height: 25px;
            }

            span {
              font-size: 12px;
              line-height: 15px;
              color: #a0a2a7;
              display: flex;
              align-items: center;

              span {
                background: #ae0e16;
                width: 10px;
                height: 10px;
                margin-left: 4px;
              }
            }
          }

          .pro-card-acc-price {
            align-self: flex-start;
            margin-left: 1rem;
            .pro-card-acc-price-content {
              display: flex;
              width: 100%;

              p {
                margin: 0;
                color: #40444e;
                font-size: 12px;
                font-weight: 600;
                line-height: 25px;
              }

              span {
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #40444e;
                padding-left: 4px;
              }
            }
          }
        }

        .pro-card-acc-btns {
          display: flex;
          position: absolute;
          bottom: 10px;
          width: 100%;
          justify-content: space-around;
          padding: 0px;
          margin: 0px;
          left: 0;

          .acc-pro-left-btn {
            min-width: 52px !important;
            border: 1px solid $color-menu-background;
            background: transparent;
            margin-right: 10px !important;
            height: 35px;
            padding: 0px 15px;

            span {
              color: $color-menu-background;
            }
          }

          .acc-pro-right-btn {
            height: 35px;
            padding: 0 15px;
            min-width: 70px !important;
            span {
              font-weight: 400;
              font-size: 13px;
            }
          }
          .acc-pro-right-btn-active {
            height: 35px;
            padding: 0 15px;
            min-width: 70px !important;
            background: $color-secondary;
            span {
              font-weight: 400;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .acc-pro-footer-btns {
    display: flex;
    margin: 25px 0px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .acc-pro-footer-left-btn {
      margin-right: 20px !important;
      background: transparent;
      border: 1px solid $color-menu-background;
      &:hover {
        background: $color-white;
      }
      &:focus {
        background: $color-white;
      }

      span {
        color: $color-menu-background;
      }
    }

    .active-back-audito {
      margin-right: 20px !important;
      background: $color-secondary;
      border: none;
      &:hover {
        background: $color-secondary;
      }
      &:focus {
        background: $color-secondary;
      }

      span {
        color: $color-white;
      }
    }
  }
}

.selected-devices-accessorries-list {
  .step-accessories-title {
    margin: 0px;
    font-size: 25px;
    margin-top: -10px;
    i {
      font-size: 18px;
      cursor: pointer;
      margin-right: 0.6rem;
    }
  }

  table {
    border-collapse: collapse;

    th {
      padding: 0.55rem;
    }

    tr.empty {
      td {
        padding: 0.55rem;
      }
    }
  }

  tbody {
    outline: 1px solid $color-primary;
    border-radius: 0.5rem;

    tr.border-bottom {
      position: relative;
      td:first-of-type {
        padding-bottom: 0.8rem;
        &::after {
          content: '';
          position: absolute;
          border-bottom: 1px solid $color-primary;
          width: calc(100% - 1.2rem);
          left: 0.6rem;
          right: 0.6rem;
          bottom: 0;
        }
      }

      & + tr {
        td {
          padding-top: 0.8rem;
        }
      }
    }

    tr {
      td {
        padding: 0.3rem 0;

        &:first-of-type {
          padding-left: 0.6rem;
        }

        &:last-of-type {
          padding-right: 0.6rem;
        }
      }
      &:first-of-type {
        td {
          padding-top: 0.8rem;
        }
      }

      &:last-of-type {
        td {
          padding-bottom: 0.8rem;
        }
      }
    }
  }

  h3 {
    padding-right: 10px;
  }

  h5 {
    margin: 35px 0px;
  }

  .img-var-selected {
    width: 55px;
    height: 55px;
    border: 0.5px solid #aeb5c7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .acc-pro-left-btn {
    box-shadow: inset 0 0 0 1px $color-menu-background;
    background: transparent;
    margin-right: 10px !important;
    height: 40px;
    padding: 0px 15px;

    span {
      color: $color-menu-background;
    }

    :hover {
      span {
        color: white;
      }
    }
  }

  .acc-pro-left-btn:hover {
    span {
      color: white;
    }
  }

  .acc-pro-right-btn {
    height: 40px;
    padding: 0px 15px;
  }

  .selected-devices-accessorries-footer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 100px;

    p {
      padding: 0px;
      margin: 0px;
      margin-right: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-primary;
    }

    .selected-btn-data-DA {
      height: 40px;
    }
  }
}

.selected-signature-footer {
  margin-right: -2px !important;
  margin-top: 20px !important;
}

.mr-2 {
  margin-right: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.step-2-accessroiess-devices {
  .img-var-selected {
    width: 55px;
    height: 55px;
    border: 0.5px solid #aeb5c7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .button-component-wrapper {
    padding-top: 0;

    & .button {
      padding: 0;
    }
  }

  td {
    span {
      font-size: 14px !important;
      line-height: 24px !important;
      color: $color-primary !important;
      font-weight: normal !important;
      align-items: center !important;
    }
  }

  th {
    width: 15%;
  }

  .acc-pro-status-btn {
    height: 35px;
    min-width: 130px;
    max-width: 170px;
    background: $color-success;
    cursor: auto;
    &:hover {
      background: $color-success !important;
    }
    &:focus {
      background: $color-success !important;
    }
    span {
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 17px !important;
      color: $color-white !important;
    }
  }

  .acc-pro-active-btn {
    height: 35px;
    min-width: 130px;
    max-width: 170px;
    background: $color-secondary;
    cursor: auto;
    &:hover {
      background: $color-secondary !important;
    }
    &:focus {
      background: $color-secondary !important;
    }
    span {
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 17px !important;
      color: $color-primary !important;
    }
  }
}

.final-step-completed {
  background: rgba(118, 187, 125, 0.15);
  border: 1px solid $color-success;
  margin: 40px 0;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  .icon {
    &:before {
      font-size: 55px;
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #70737a;
  }
}
.not-final-step-completed {
  background: rgba(218, 17, 17, 0.356);
  border: 1px solid $color-error;
  margin: 40px 0;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  .icon {
    &:before {
      font-size: 55px;
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #70737a;
  }
}


.step-process-pro {
  display: flex;
  justify-content: center;

  .step-process-pro-btn {
    border: none;
    background-color: $color-success;
    color: $color-white;
    display: flex;
    padding: 5px 20px;
    align-items: center;
    border-radius: 30px;
    p {
      padding: 0px;
      margin: 0px;
    }
    i {
      margin-top: -10px;
      margin-left: 10px;
      &:before {
        font-size: 34px;
      }
    }
  }
}

.acc-pro-checkboxes .checkbox > input:checked::before {
  left: 0.3rem;
  top: 0.2rem;
}

.acc-pro-search input:focus-visible {
  outline: none;
}

.fine-tune {
  & .edit {
    height: 28px !important;
    &:hover {
      background: #1a3d5e !important;
    }
  }
}

.document-content-cus {
  margin-top: 120px;
  display: flex;
  .btn-document-cus {
    background: $color-white;
    border: 1px solid $color-menu-background;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 0px 28px;
    display: flex;
    align-items: center;
    height: 40px;
    margin-right: 10px;

    .icon {
      margin-right: 5px;
    }
    .icon-scanner {
      margin-top: -14px;
      margin-right: 5px;
      &::before {
        font-size: 30px;
      }
    }
    .document-ti-cus {
      color: $color-menu-background;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }
  }
}

.document-pdf-show {
  margin-top: 50px;
  width: 100%;
  height: 500px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.tbl-device-two {
  text-align: left;
  border-collapse: collapse;
  th:nth-child(1) {
    width: 10%;
  }
  th:nth-child(2) {
    width: 23%;
  }
  th:nth-child(3) {
    width: 25%;
  }
  th:nth-child(4) {
    width: 17%;
  }
  th:nth-child(5) {
    width: 25%;
  }

  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 18px 5px;
  }

  tbody {
    vertical-align: top;
    tr {
      td {
        padding-left: 18px;
        .image-container {
          height: 50px;
          .image {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        &:nth-of-type(5) {
          padding-right: 20px;
        }
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 10px;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #5c6170;
          font-weight: 600;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}

.tbl-device-date-time {
  text-align: left;
  border-collapse: collapse;

  td,
  th {
    padding: 18px 5px;
  }

  tbody {
    vertical-align: top;
    tr {
      td {
        padding-left: 18px;
        .image-container {
          height: 50px;
          .image {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        &:nth-of-type(5) {
          padding-right: 20px;
        }
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 10px;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #5c6170;
          font-weight: 600;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}

.tbl-document {
  text-align: left;
  border-collapse: collapse;

  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 0px 5px;
  }

  tbody {
    vertical-align: top;

    tr {
      display: flex;
      td:nth-child(1) {
        width: 40%;
      }
      td:nth-child(2) {
        width: 40%;
      }
      td:nth-child(3) {
        width: 15%;
      }
      td:nth-child(4) {
        width: 5%;
      }
      td {
        display: flex;
        align-items: center;
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 10px;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #5c6170;
          font-weight: 600;
        }
      }
      td.custom-doc-cell {
        padding-left: 15px;
        width: 95%;
        min-height: 48px;
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}

.error-message-customfile {
  color: red;
  font-size: 14px;
}

.comments-heading {
  color: #40444e;
  font-weight: 600;
}

.comments-list {
  & > div {
    & > p {
      font-size: 14px;
      color: #70737a;
    }
    &:nth-of-type(2) {
      & > p {
        text-align: right;
      }
    }
  }
}

.status {
  border-radius: 40px;
  text-align: center;
  padding: 5px 0;
  font-size: 15px;
  &.status-active {
    background-color: $color-success;
    color: $color-white;
  }
  &.status-inactive {
    background-color: $color-primary;
    color: $color-white;
  }
  &.status-inactive-dark {
    background-color: $color-primary;
    color: $color-white;
  }
  &.status-canceld {
    background-color: $color-error;
    color: $color-white;
  }

  &.status-progress {
    background-color: $color-secondary;
    color: $color-primary !important;
    &:hover {
      background: $color-secondary-hover !important;
      color: $color-white !important;
    }
  }
}

.icon-alert-triangle {
  font-size: 24px;
}
.text-tbl-normal {
  line-height: 24px;
}
.DayPicker-Day--selected {
  background-color: red;
  color: white;
}
.day-picker {
  .DayPicker-Day--today {
    color: #272d30;
    font-weight: 400;
  }

  .react-timekeeper__tb-hour,
  .react-timekeeper__tb-minute {
    border: 2px solid $color-text-muted;
    padding: 0 8px;
    border-radius: 6px;
  }

  .react-timekeeper__tb-hour.react-timekeeper__tb-hour--active,
  .react-timekeeper__tb-minute.react-timekeeper__tb-minute--active {
    border: 2px solid $color-primary;
  }
}
.ordered-items-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  .card {
    box-shadow: 8px 8px 14px 0 #aeb5c7;
    .content {
      p {
        margin: 0;
      }
      .title,
      .price {
        color: #40444e;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
      }

      .small-detail {
        font-size: 12px;
        line-height: 16px;
        color: #a0a2a7;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin: 0 25px;
      .secondary {
        background: transparent;
        padding: 8px 32px;
        border-radius: 40px;
        border: 1px solid $color-menu-background;
        color: $color-menu-background;
        font-size: 15px;
        line-height: 16px;
      }
      .primary {
        padding: 8px 32px;
        border-radius: 40px;
        font-size: 15px;
        line-height: 16px;
        color: $color-white;
        border: none;
        &.btn-active {
          background: $color-success;
        }
        &.btn-inactive {
          background: $color-menu-background;
        }
      }
    }
  }
}
.device-filters {
  width: 25%;
  height: calc(100vh - 280px);
  max-height: 440px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f7f8fa;
    border-radius: 500px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aeb5c7;
    border-radius: 500px;
  }
}
.audito-device-model-suplly {
  width: 100%;
  text-align: center;
  margin: auto;
}
.device-select-modal {
  h1 {
    i {
      font-size: 22px;
    }
  }
  .mr-1 {
    margin-right: 1rem;
  }
  .device-details-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    overflow-y: auto;
    height: calc(100vh - 280px);
    max-height: 440px;
    padding: 0.5rem 0 1rem 1rem;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f7f8fa;
      border-radius: 500px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aeb5c7;
      border-radius: 500px;
    }

    /* Handle on hover */

    .device-detail-card {
      box-shadow: 8px 8px 32px rgba(174, 181, 199, 0.2);
      padding: 10px;
      border-radius: 8px;
      position: relative;
      height: 295px;
      width: 95%;
      margin-bottom: 0.8rem;

      .device-image {
        p {
          position: absolute;
          background: $color-secondary;
          color: $color-white;
          top: -20px;
          left: -20px;
          margin: 0;
          padding: 5px 25px;
          border-top-left-radius: 8px;
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 90% 50%, 100% 0%);
        }
        img {
          image-rendering: -webkit-optimize-contrast;
        }
      }
      .device-detail {
        .device-main-info {
          display: flex;
          justify-content: space-between;
          margin: 0px 0;
          width: 100%;
          p {
            margin: 0;
            color: #40444e;
            font-size: 12px;
            font-weight: 600;
            line-height: 25px;
          }
          .price-tag {
            width: 30%;
            text-align: end;
          }
        }
        .device-secondary-info {
          display: flex;
          justify-content: space-between;
          margin: 0px 0 10px 0;
          p {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            color: #a0a2a7;
          }
        }
        .buttons {
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 10px;
          width: 100%;
          justify-content: space-between;
          padding: 0px 20px 0px 0px;
          .secondary {
            background: transparent;
            padding: 8px 15px;
            border-radius: 40px;
            color: $color-menu-background;
            font-size: 14px;
            line-height: 15px;
          }
          .primary {
            padding: 8px 25px;
            border-radius: 40px;
            font-size: 14px;
            line-height: 15px;
            color: $color-white;
            border: none;
            &.btn-notselected {
              background: $color-secondary;
              &:hover {
                background: $color-secondary-hover !important;
                color: $color-white !important;
              }
            }
            &.btn-selected {
              background: $color-menu-background;
            }
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .secondary {
      background: transparent;
      padding: 8px 22px;
      border-radius: 40px;
      border: 1px solid $color-menu-background;
      color: $color-menu-background;
      font-size: 15px;
      line-height: 16px;
      margin-right: 20px;
    }
    .primary {
      padding: 8px 32px;
      border-radius: 40px;
      font-size: 15px;
      line-height: 16px;
      color: $color-white;
      border: none;
      background: $color-menu-background;
    }
  }
}

.audito-device-paginate {
  width: 100%;
  margin: auto;
  margin-top: 1rem;

  ul {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    li {
      padding: 10px;
      font-size: 14px;
      color: $color-primary;
      font-weight: 600;
      a {
        color: $color-primary;
      }
    }

    .audito-active-paginate {
      background: $color-menu-background;
      border-radius: 50%;
      height: 35px;
      text-align: center;
      width: 35px;
      a {
        color: $color-white !important;
      }
    }
  }
}

.device-audito-modal {
  background: transparent !important;
  color: $color-menu-background !important;
  border: 1px solid $color-menu-background !important;
}

.device-audito-modal-secondary {
  background: $color-secondary !important;
  border: none !important;
  &:hover {
    background: $color-secondary-hover !important;
    color: $color-white !important;
  }
}

.patient-select-modal {
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 50px 30px;
  justify-content: center;
  text-align: center;

  .icon.icon-search {
    &::before {
      font-size: 35px;
    }
  }

  .icon.icon-profile {
    margin-top: -20px;
    &::before {
      font-size: 55px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    padding: 0px 5%;
    color: #70737a;

    span {
      font-weight: bold;
    }
  }
}

.doctor-select-modal {
  min-height: 250px;
  max-height: 500px;
  overflow: auto;
  position: relative;

  & p {
    margin-top: 115px;
  }
}

.add-parient-modal-credit {
  background: #f7f8fa;
  border-radius: 8px;
  display: flex;
  padding: 5px;
  align-items: center;
  .icon.icon-creditcard {
    margin-top: -40px;
    &::before {
      font-size: 7em;
    }
  }
  p {
    padding-left: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #40444e;
  }

  .info-left-patient {
    border-right: 1px solid #ccc;

    li {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #40444e;
      padding-bottom: 2px;
    }
  }

  .info-right-patient {
    li {
      font-size: 14px;
      line-height: 18px;
      color: #40444e;
      padding-bottom: 2px;
    }
  }

  .icon.icon-profile {
    margin-top: -40px;
    &::before {
      font-size: 7em;
    }
  }

  .consultation-user-info {
    background: #f7f8fa;
    border-radius: 16px;
    padding: 5px;
    align-items: center;
    margin-left: 2%;

    .consultation-user-profile-warapper {
      background: $color-secondary;
      border-radius: 24px;
      width: 70px;
      align-items: center;
      text-align: center;

      p {
        width: 100%;
        height: 100%;
        justify-content: center;
        padding-top: 15%;
        font-size: 39px;
        line-height: 58px;
        color: $color-white;
        font-weight: bold;
      }
    }

    .consultation-user-left {
      border-right: 0.5px solid #aeb5c7;
      padding-right: 15px;

      li {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #40444e;
        padding: 2px 0px;
      }
    }

    .consultation-user-right {
      padding-left: 15px;

      li {
        color: #40444e;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        padding: 2px 0px;
      }
    }
  }
}

.add-parient-modal-para {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #40444e;
  margin-top: 40px;
}

.add-patient-btn-modal {
  text-align: center;
  margin-top: 25px;
}

.p-relative {
  position: relative;
}

.add-patient-border-modal {
  border-bottom: 1px solid #f7f8fa;
  padding-top: 20px;
  padding-bottom: 20px;
}

.add-patient-text-modal {
  position: absolute;
  top: 15px;
  left: 45%;
  background-color: $color-white;
  padding: 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: #40444e;
}

.btn-patient-cons {
  display: flex;
  background: transparent !important;
  color: $color-menu-background !important;
  border: 1px solid $color-menu-background;
  border-radius: 20px;
  height: 30px;
  margin-top: 15px;
  padding: 5px 7px;
  align-items: center;
  width: 120px;
  justify-content: center;
  text-align: center;

  &:hover {
    background: $color-secondary !important;
    color: $color-menu-background !important;
    border: none !important;
  }

  .icon {
    margin-top: 4px;
    margin-right: 4px;
  }

  span {
    font-size: 12px;
  }
}

.price-selector {
  display: flex;
  input {
    width: 60px;
    border-radius: 8px;
    border: 1px solid #000;
    outline: none;
    text-align: center;
    background-color: #f7f8fa;
    padding: 5px 0;
    color: #70737a;
    caret-color: #70737a;
    padding-left: 18px;
  }
  span {
    margin: 0 5px;
  }
}

span.euro-icon {
  margin: 0;
  position: relative;

  input {
    padding-left: 18px;
    padding-right: 12px;
  }

  &::before {
    content: '€';
    position: absolute;
    left: 6px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.fontBloder {
  font-size: 20px;
  font-weight: bolder;
}
.list-advanced-search-component {
  position: relative;

  .icon,
  .input-search {
    line-height: 20px;
    font-size: 14px;
    color: $color-text-light;
  }

  .icon {
    position: absolute;
    top: 8px;
    left: 15px;
    display: inline-block;
  }

  .input-search {
    font-size: 13px;
    display: block;

    input {
      position: absolute;
      height: 35px;
      padding: 0 0px 0 38px;
      line-height: 35px;
      border-radius: 4px;
      border: 0;
      outline: 0;
    }
  }

  .icon {
    &:before {
      font-size: 10px;
    }
  }

  span {
    font-size: 12px;
    padding: 5px 2px !important;
  }
}

.new-comment-textarea {
  width: 100%;
  padding: 1rem;
  background: #f7f8fa;
  border: 0;
  outline: 0;
  border-radius: 8px;
}
.new-comment-textarea-button {
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: transparent;
  border: 1px solid $color-menu-background;
  padding: 6px 20px;
  border-radius: 220px;
  font-size: 15px;
  color: $color-menu-background;
}

.date-time-button {
  background-color: $color-secondary;
  height: 38px !important;
  margin-top: 40px !important;
}

.time-picker-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  .separator {
    font-size: 1rem;
    font-weight: bold;
  }

  & .Select-control {
    display: flex;
    align-items: center;
    width: 40px;
    gap: 8px;
  }

  & .Select-value-label {
    font-size: 1.1rem;
  }

  & .Select-input {
    input {
      width: 15px;
    }
  }
}

div.react-timekeeper {
  //
  --top-selected-color: var(--color-secondary);
  --hand-circle-outer: var(--color-secondary-light);
  --hand-circle-center: var(--color-secondary);
  --hand-line-color: var(--color-secondary-light);
  --hand-minute-circle: var(--color-secondary-light);

  & .done-btn {
    text-align: center;
    margin-bottom: 0.8rem;
  }
}

.timepicker {
  width: 80%;
}

.selectInput {
}

.appoitment-bar {
  padding: 0 15px;
  margin-top: 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8fa;
  span {
    padding: 5px 10px;
    font-weight: 700;
    cursor: pointer;
  }
}

.canceled-status {
  color: $color-secondary;
}

.confirmed-status {
  color: $color-success;
}
.status-confirm {
  background: $color-primary;
  color: white;
  padding: 15px 10px;
}
.icon-date {
  font-size: 25px;
}

.icon-attention-circle {
  font-size: 25px !important;
  color: $color-secondary !important;
  margin-right: 5px;
}
.icon-check-circle {
  font-size: 25px !important;
  color: $color-success !important;
  margin-right: 5px;
}

.heading-button {
  font-size: 15px;
  font-weight: 500;
  color: $color-menu-background;
  padding: 5px 15px;
  background: none;
  border-radius: 15px;
  border: 1px solid $color-menu-background;
}

.error-boundry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  div {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .icon-attention-circle {
      font-size: 50px !important;
    }
    button {
      padding: 5px 15px;
      border-radius: 50px;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.primary {
        background: $color-menu-background;
        border: 1px solid $color-menu-background;
        color: $color-white;
      }
      &.secondary {
        background: none;
        border: 1px solid $color-menu-background;
        color: $color-menu-background;
      }
    }
  }
  .error-heading {
    font-size: 48px;
    font-weight: 600;
    color: #40444e;
    text-transform: uppercase;
  }
  .sub-heading {
    font-size: 400;
    color: #70737a;
    font-size: 16px;
  }
}
.icon-check-circle {
  color: $color-menu-background !important;
  margin-top: -8px;
  font-size: 35px !important;
}
.cta-action-buttons {
  padding-top: 20px;
  display: flex;

  button {
    background: none;
    border: 1px solid $color-menu-background;
    border-radius: 150px;
    color: $color-menu-background;
    font-weight: 500;
    padding: 5px 20px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 15px;
    }

    .icon {
      margin: 0px;
      margin-right: 5px;
      margin-top: 2px;
    }
    .icon-scanner {
      margin-right: 5px;
      margin-top: -12px;
      &::before {
        font-size: 25px;
      }
    }
    .document-ti-cus {
      color: $color-menu-background;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }
  }
}

.react-pdf__Page {
  padding: 0px 15%;
  canvas {
    width: 100% !important;
  }
}

.pdf-window {
  width: 100%;
  height: 65vh;
}
.signaturer-name {
  color: red;
}
.active-signature {
  z-index: 11111 !important;
  border: 3px solid red;
}
#pageContainer
  .annotationLayer
  .buttonWidgetAnnotation.checkBox
  input:checked::before {
  content: '✔';
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none;
  font-size: 110%;
}
#pageContainer
  .annotationLayer
  .buttonWidgetAnnotation.checkBox
  input:checked:after {
  content: unset;
  width: 2px;
}
.signature-section {
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.btn-document-upload {
  display: flex;
  background: rgb(255, 188, 82);
  border-radius: 40px;
  padding: 8px 32px;
  margin-top: 5px;
  border-style: none;

  i {
    margin-top: -16px;
    margin-right: 5px;

    &:before {
      font-size: 22px;
    }
  }
  p {
    font-weight: 500;
    font-size: 15px;
    color: rgb(19, 46, 72);
    line-height: 17px;
    margin: 0px;
  }
}

.cta-button {
  background: none;
  border: 1px solid $color-menu-background;
  border-radius: 150px;
  background: $color-menu-background;
  color: $color-white;
  font-weight: 500;
  padding: 5px 20px;
}

.pdfViewer {
  .page {
    margin: auto !important;
    // width: 100% !important;

    .canvasWrapper {
      overflow: unset;
      // width: 100% !important;
      canvas {
        // width: 100% !important;
      }
    }

    // On safari PDF text was looking blur
    .annotationLayer {
      position: absolute;
      top: 0;
    }
  }
}

.scan-doc-width100 .page {
  width: 100% !important;
}
.scan-doc-width100 .canvasWrapper {
  width: 100% !important;
}
.scan-doc-width100 .textLayer {
  width: 100% !important;
}
.audito-search-com-modal {
  .icon {
    left: 0px;
  }
  .input-search input {
    width: 100%;
    padding: 0 0px 0 20px;
  }
}

@import '../../../abstracts/variables';

.dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;

  & .dashboard-select-item-button {
    width: 150px;
    background: transparent;
    border: 1px solid $color-menu-background;
    border-radius: 50px;
    padding: 10px 0;
    font-size: 15px;
    color: $color-menu-background;
    font-weight: 500;
    &:hover {
      background: $color-success;
      color: $color-white;
      border: 1px solid $color-success;
    }
    &.active {
      background: $color-success;
      color: $color-white;
      border: 1px solid $color-success;
    }
  }
}

.consultation-error {
  background: rgba(255, 241, 225, 0.5);
  border: 1px solid $color-secondary;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  width: 98%;
  margin-inline: auto;

  .icon {
    color: #d98c03;
    &::before {
      font-size: 35px;
      padding-left: 10px;
      margin-right: -10px;
    }
  }

  p {
    color: #d98c03;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 20px;
  }
}
.custom-wrapper {
  .list-advanced-component .list-advanced-head {
    top: 250px !important;
  }
  .list-advanced-component {
    padding-top: 50px !important;
  }
}

.no-margin {
  margin: 0 !important;
}

.status-cells {
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
  margin-top: 13px;
  gap: 10px;
  .status-cell {
    border: 1px solid #aeb5c7;
    color: #aeb5c7;
    padding: 0px 16px;
    border-radius: 15px;
    line-height: 1.6;
    background: $color-white;
    &.locked {
      background-color: $color-success;
      color: $color-white;
      border: 1px solid $color-success;
    }
    &.active {
      background: $color-secondary;
      color: $color-primary;
      border: 1px solid $color-secondary;
    }
    &.completed {
      border: 1px solid $color-secondary;
      color: $color-secondary;
      background: #fff8f1;
    }
    &.canceled{
      border: 1px solid $color-error;
      color: $color-white;
      background: $color-error;
    }
  }
}

.audtio-gray-label {
  color: #40444e !important;
}

.audtio-red-label {
  color: #ae0e16 !important;
}

.audtio-green-label {
  color: $color-success !important;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f7f8fa;
    border-radius: 500px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aeb5c7;
    border-radius: 500px;
  }
}

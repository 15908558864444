@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.device-detail-wrapper {
  .device-header {
    & i {
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }
  .pagebox_content-divided {
    .buttons {
      display: flex;

      .button-component-wrapper:first-of-type {
        margin-right: 10px;
      }
    }
    .text-blue {
      color: #0c85ff;
    }
    .row {
      padding-top: 20px;
      .col-divided {
        border-right: 2px solid #e3e3e3;
        padding-right: 32px;
      }
      .col-divided + div {
        padding-left: 32px;
      }
    }
  }
  .pagebox_content-divided-nopadding {
    .unbox {
      padding-left: 0px;
      .row-border-bottom {
        margin-left: 0px;

        .view-hover {
          margin-top: -8px;
          margin-left: 10px;
          position: relative;

          &:before {
            font-size: 26px;
          }

          .hover-view-cus {
            display: none;
            background: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            @include rem(box-shadow, 3px 4px 4px #d8d8d8);
            border-radius: 4px;
            padding: 2px 12px;
            position: absolute;
            top: 20px;
            left: 8px;
          }

          &:hover {
            .hover-view-cus {
              display: block;
            }
          }
        }
      }
    }
  }
  .pagebox_content-divided-padding {
    .unbox {
      .row-border-bottom {
        margin-left: 0px;
      }
    }
  }
  .custom-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .contactPersonsCarousels {
    min-height: 410px;
    .react-multiple-carousel__arrow--left {
      left: -10px;
      background-color: transparent;
    }
    .react-multiple-carousel__arrow--right {
      right: -10px;
      background-color: transparent;
    }
    .react-multiple-carousel__arrow {
      &:before {
        color: #333;
      }
    }
    .mlt-cont {
      margin-top: 16px;

      li {
        cursor: pointer;
        display: inline-block;
        border: 3px solid #d2d2d2;
        color: #d2d2d2;
        font-weight: 600;
        text-align: center;
        border-radius: 50px;
        font-size: 11px;
        height: 40px;
        line-height: 35.5px;
        margin-bottom: 8px;
        margin-right: 10px;
        width: 40px;
      }

      .active {
        border: 3px solid $color-primary-light;
        color: $color-primary-light;
      }
    }
  }

  .device-image {
    width: 100%;
    justify-items: center;
    display: grid;
  }

  .getActiveColor {
    color: $color-success !important;
  }

  .variant-cus-add {
    background: #f7f8fa;
    border-radius: 8px;
    height: 70px;
    width: 100%;
    border: none;
    margin-bottom: 8%;
    color: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      font-size: 24px;
    }

    p {
      padding: 0px;
      margin: 0px;
      padding-top: 12px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .no-variant-container {
    border: 2px dashed $color-secondary;
    background: $color-white;
    box-sizing: border-box;
    @include rem(box-shadow, 0px 25px 53px #d8d8d8);
    border-radius: 16px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;

    .icon {
      font-size: 67px;
      color: rgb(19, 45, 69);
    }

    .text-h21 {
      padding: 0px 0px 44px;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .no-image-container-without-text {
    border: 2px dashed $color-secondary;
    height: 50px;
    width: 60px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      padding: 2px;
      object-fit: contain;
    }
  }

  .no-image-container-without-text.read-only {
    border: none;
    cursor: not-allowed;
  }

  .no-image-container {
    width: 90px;
    text-align: center;
    border: 2px dashed $color-secondary;
    box-sizing: border-box;
    // @include rem(box-shadow, 0px 25px 53px 6px #f3f3f3);
    border-radius: 16px;
    background: $color-white;
    cursor: pointer;
  }

  .devicemain {
    @include rem(box-shadow, 0px 0px 11px 6px #f3f3f3);
    width: 100%;
    min-height: 260px;
    border-radius: 16px;

    .add-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: $color-white;
      border: 2px dashed $color-secondary;
      box-sizing: border-box;
      @include rem(box-shadow, 0px 25px 53px 6px #f3f3f3);
      border-radius: 16px;
      min-height: 275px;

      .upload-file {
        width: 60%;
        margin-left: 25%;
        margin-top: 5%;
      }
    }

    img.device-image {
      padding: 10px;
      height: 275px;
      object-fit: contain;
    }

    .devicemain-action {
      height: 50px;
      background: #f7f8fa;
      text-align: end;
      padding-right: 10px;
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;

      .custom-file-input {
        color: transparent;
        cursor: pointer;

        &::-webkit-file-upload-button {
          visibility: hidden;
        }

        &::before {
          color: black;
          float: right;
          font-size: 22px;
          padding-right: 10px;
        }
      }

      button {
        border: none;
        background: transparent;

        &::before {
          font-size: 22px;
        }
      }
    }
  }

  .square-color-box {
    width: 20px;
    height: 20px;
    border-radius: 8px;
  }

  .ch_head {
    margin-bottom: 0;
  }

  .cp_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 114px;

    &-small {
      justify-content: flex-start;
      margin-right: 17px;
    }
  }

  .cp_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $color-primary-light;
    border-radius: 50%;
    color: $color-primary-light;
    height: 114px;
    width: 114px;
    margin: 0px;
    font-size: 42px;

    &-small {
      font-size: 24px;
      height: 70px;
      width: 70px;
    }
  }

  .cp_text_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    width: 100%;

    &-small {
      height: 114px;

      & > :nth-child(2) {
        margin-top: 0 !important;
      }
    }

    & > :nth-child(2) {
      margin-top: 40px;
    }
  }

  .cp_sbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  // -----------------------------
  // detail view graph
  // -----------------------------
  .graph-cont {
    margin-top: 50px;

    .chart {
      height: 280px;
      margin-bottom: 62px;
    }
  }

  // -----------------------------
  // detail view map
  // -----------------------------
  .address-map {
    background: url('../../../../assets/images/maps_bg.jpg') no-repeat center
      center !important;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 320px;
  }

  .mapInfo {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 360px;
    margin-right: 48px;
    padding: 44px 60px 36px;

    &_heading {
      line-height: 28px;
      margin-bottom: 8px;
    }

    &_subheading {
      font-size: 15px;
      color: $color-text-muted;
    }

    &_buttons {
      margin-top: 36px;
    }
  }

  //table contact Person
  table {
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
  }
  .tbl-varient-health {
    text-align: left;
    border-collapse: collapse;
    th:nth-child(1) {
      width: 33%;
    }
    th:nth-child(2) {
      width: 33%;
    }
    th:nth-child(3) {
      width: 33%;
    }

    th {
      padding-bottom: 20px;
      font-size: 14px;
    }
    td,
    th {
      padding: 18px 5px;
    }

    tbody {
      vertical-align: top;
      tr {
        td {
          strong,
          span {
            display: block;
          }
          strong {
            font-size: 10px;
            font-weight: 700;
          }
          span {
            font-size: 10px;
            color: #5c6170;
            font-weight: 600;
          }
        }
      }
      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
    }
  }
  .tbl-varient {
    text-align: left;
    border-collapse: collapse;
    th:nth-child(1) {
      width: 15%;
    }
    th:nth-child(2) {
      width: 20%;
    }
    th:nth-child(3) {
      width: 20%;
    }
    th:nth-child(4) {
      width: 15%;
    }
    th:nth-child(5) {
      width: 15%;
    }
    th:nth-child(6) {
      width: 15%;
    }
    th {
      padding-bottom: 20px;
      font-size: 14px;
    }
    td,
    th {
      padding: 18px 5px;
    }

    tbody {
      vertical-align: top;
      tr {
        td {
          strong,
          span {
            display: block;
          }
          strong {
            font-size: 10px;
            font-weight: 700;
          }
          span {
            font-size: 10px;
            color: #5c6170;
            font-weight: 600;
          }
        }
      }
      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
    }
  }

  .tablerow {
    display: table-row;
  }
  .tablecell {
    display: table-cell;
    vertical-align: top;
  }

  // -----------------------------
  // edit view contact person list
  // -----------------------------
  .contactPerson {
    &_item {
      & .contactPerson_column {
        min-height: 80px;
      }

      &:hover {
        button.button {
          display: inline-block !important;
        }
      }

      &:last-child {
        margin-bottom: 20px;

        & .contactPerson_column {
          border-bottom: 1px solid #cccccc;
        }
      }

      .contact-communication {
        .row:first-child {
          margin-top: 0 !important;
        }

        .row:last-child {
          margin-bottom: 0 !important;
        }

        .commValue {
          word-wrap: break-word;
        }
      }
    }
  }

  // -----------------------------
  // Customer Notes
  // -----------------------------
  .customerNotes {
    .htmlInput_controls {
      bottom: 0;
    }
  }

  // -----------------------------
  // Customer History
  // -----------------------------
  .customerHistory {
    &_container {
      display: block !important;
    }

    &_list {
      width: 100%;

      .list_row {
        padding-left: 5px;
        padding-right: 5px;

        &-canceled {
          .list_item:nth-child(4) {
            padding-right: 0px;
          }
        }
      }

      .list_info {
        padding-left: 5px;
        padding-right: 6px;
      }
    }
  }

  .selectize-full-width {
    .selectize-dropdown {
      width: 480px !important;
    }
  }

  .selectInput_label {
    width: 50%;
  }

  .customer-history-list-head-content {
    position: relative;
    width: 100%;
    padding: 10px 0;
  }

  .list-component {
    .list-table {
      .list-table-cell {
        padding: 25px 0 !important;
      }
    }
  }

  .customer-history-list-pagination {
    text-align: center;
    padding: 50px 0 0 0;
  }

  .customerHistory_container {
    min-height: 400px;
  }

  .customer-history-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error-headline {
      margin-bottom: 20px;

      h1 {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .companyNameDisplay {
    color: #000000;
    font-weight: 700;
    font-size: 30px;
  }

  .notes .row > div:last-child {
    padding-left: 10px;
    border-top: 1px solid $color-input-bar;
  }

  .box-gradient .htmlInput_input {
    border: 1px solid transparent;
    border-image: linear-gradient(to top, #7c7c7c, white);
    border-image-slice: 1;
    color: #666;
  }

  .text-tbl-normal {
    color: rgb(19, 45, 69) !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .text-tbl-block {    
    color: rgb(19, 45, 69) !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }

  .map-preview {
    height: 279px;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }
    .leaflet-bottom {
      display: none;
    }
    .leaflet-top {
      display: none;
    }
    .map-overlap {
      height: 100%;
      position: relative;
      width: 100%;
      .map-overlap-view {
        position: absolute;
        background: transparent;
        width: 100%;
        height: 100%;
        top: 0;
        cursor: pointer;
      }
    }
  }

  .dropdown-faq {
    position: relative;
    display: inline-block;
  }

  .dropdown-content-faq {
    display: none;
    position: absolute;
    z-index: 1;
    background: $color-white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 300px;
    padding: 26px 24px;
  }
}
.device-accesories-relation-conatiner {
  padding-top: 0;
  height: 100%;
}
.faq-table-content p {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $color-primary !important;
  margin: 5px 0;
}
.faq-table-content span {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $color-primary !important;
  margin: 5px 0;
}
.faq-document-download a {
  text-decoration-line: underline;
  font-weight: 500;
  color: $color-primary;
  font-size: 16px;
  line-height: 24px;
  margin-right: 7px;
  margin-top: 6px;
}
.faq-document-download .icon::before {
  color: $color-primary;
  font-size: 24px;
}
.faq-table-content .icon.icon-arrow-left {
  margin-right: 5px;
  margin-top: -10px;
}
.faq-table-content .icon.icon-arrow-left::before {
  font-size: 30px;
}

.dropdown-faq:hover .dropdown-content-faq {
  display: grid;
}
.add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $color-white;
  border: 2px dashed $color-secondary;
  box-sizing: border-box;
  @include rem(box-shadow, 0px 25px 53px 6px #f3f3f3);
  border-radius: 16px;
  min-height: 275px;

  .upload-file {
    width: 60%;
    margin-left: 25%;
    margin-top: 5%;
  }
}
.no-variant-container {
  border: 2px dashed $color-secondary;
  background: $color-white;
  box-sizing: border-box;
  @include rem(box-shadow, 0px 25px 53px #d8d8d8);
  border-radius: 16px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;

  .icon {
    font-size: 67px;
    color: rgb(19, 45, 69);
  }

  .text-h21 {
    padding: 0px 0px 44px;
    font-weight: 600;
    font-size: 15px;
  }
}
.variant-cus-add {
  background: $color-white;
  border-radius: 8px;
  height: 70px;
  width: 100%;
  border: none;
  margin-bottom: 8%;
  color: $color-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 8px 20px rgba(146, 146, 146, 0.16);

  &::before {
    font-size: 24px;
  }

  p {
    padding: 0px;
    margin: 0px;
    padding-top: 12px;
    padding-left: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
  }
}

.stampUpload {
  padding: 0px 20px;
  .audito-imageFit {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: '#40444E';
  }
  .variant-cus-add {
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 9;
      cursor: pointer;
    }
  }
}

.tbl-customer {
  text-align: left;
  border-collapse: collapse;
  th:nth-child(1) {
    width: 20%;
  }
  th:nth-child(2) {
    width: 13%;
  }
  th:nth-child(3) {
    width: 30%;
  }
  th:nth-child(4) {
    width: 17%;
  }
  th:nth-child(5) {
    width: 20%;
  }

  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 18px 5px;
  }

  tbody {
    vertical-align: top;
    tr {
      td {
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 10px;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #5c6170;
          font-weight: 600;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}
.map-preview {
  height: 279px;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
  .leaflet-bottom {
    display: none;
  }
  .leaflet-top {
    display: none;
  }
  .map-overlap {
    height: 100%;
    position: relative;
    width: 100%;
    .map-overlap-view {
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      cursor: pointer;
    }
  }
}
.tbl-customer-health {
  text-align: left;
  border-collapse: collapse;
  th:nth-child(1) {
    width: 30%;
  }
  th:nth-child(2) {
    width: 30%;
  }
  th:nth-child(3) {
    width: 30%;
  }
  th:nth-child(4) {
    width: 10%;
  }

  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 18px 5px;
  }

  tbody {
    vertical-align: top;
    tr {
      td {
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 10px;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #5c6170;
          font-weight: 600;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}

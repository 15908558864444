@import '../../../abstracts/_variables';
@import '../../../abstracts/_mixins';

@import 'menu-bar';
@import 'menu-footer';
@import 'menu-header';
@import 'menu-item';
@import 'menu-item-with-submenu';

.menu {
  background: $color-menu-background;
  position: fixed;
  z-index: 10010;

  @include breakpoint-lg {
    min-height: 100%;
    @include rem(width, $menu-width);
    @include rem(padding, 0, 0, 60, 0);
    left: 0;
  }

  @include breakpoint-md {
    min-height: 100%;
    @include rem(width, $menu-small-width);
    @include rem(padding, 0, 0, 60, 0);
    left: 0;
  }

  .logo {
    height: 100%;

    @include breakpoint-md {
      @include rem(margin-left, 0);
      width: auto;
      fill: $color-white;
    }

    @include breakpoint-lg {
      @include rem(margin-left, 0);
      fill: $color-white;
      text-align: center;
      width: 45%;
    }
    img {
      display: inline-block;
    }
  }
}

.menu-without-submenu {
  width: 150px;
  .logo {
    width: auto;
  }
  .menuItem {
    width: 150px;
  }
}

.trial-locked {
  pointer-events: none;
  opacity: 0.5;
}

.hide-submenu.layout-nav {
  margin-left: 150px;
  .menu {
    width: 150px;
    .logo {
      width: auto;
    }
    .menuItem {
      width: 150px;
    }
    & .submenu {
      display: none;
    }
  }
  .topbar-wrapper {
    left: 150px;
  }
}
@import '../../../abstracts/variables';

.info-page-wrapper {
  height: calc(100vh - 65px);
  margin-top: 65px;

  .content-conainer {
    overflow: auto;
    height: calc(100vh - 65px);
    padding-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: pre-line;
    text-align: center;
    color: $color-primary;

    .logo {
      height: 70px;
      margin-bottom: 1rem;
      width: unset;
    }

    h4 {
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 0.25rem;
    }

    h6 {
      font-size: 1rem;
      margin: 0.25rem;
      & > p {
        margin: 0;
      }
    }

    p {
      font-size: 1rem;
      margin: 0.25rem;
    }

    & * {
      color: $color-primary;
    }
  }
}

@import "../../../abstracts/_mixins";
@import "../../../abstracts/_variables";

.edit-service-technician-component {

	.modal-base-content {
		margin-top: 0!important;
		overflow: visible!important;
		 .modal-base-headline {
			margin-bottom: 30px !important;
	}
	.warning-text{
		color: #CF2745;
		font-size: 14px;
		margin-bottom: 20px;
		
		strong{
			text-transform: uppercase;
			font-weight: bold;
			margin-right:15px;
			}
	}
	.Select-placeholder, .Select-input {
		padding-left: 20px !important;
		line-height: 37px;
	}
	.Select-control{
		box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    	border-radius: 30px;
		}
		.service-technician{
		border-collapse: collapse;
		td,th{
			padding:10px 0;
			border-bottom: 1px solid #C9CED6;
		}	
		
		}
		
	}
	.modal-base-footer {
		bottom: 20px !important;
    	border-top: none !important;
		.modal-base-cancel {
			margin-left: -30px;
		}
		.button {
			border-radius: 50px;
		}
	}
	.input_error {
		position: absolute;
		bottom: 0;
		height: 0;
		top: auto;
	}

}

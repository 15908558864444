@import '../abstracts/_variables';
@import '../abstracts/_mixins';

@mixin button-color($bgColor: lightgray, $textColor: white, $step: 13) {
  color: $textColor;
  background: $bgColor;

  &::after {
    background: lighten($bgColor, $step);
  }

  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: $textColor;
  }

  &.button-square,
  &.button-square-large {
    background: $bgColor;
    &:not(:disabled) {
      &:hover,
      &:focus {
        background: lighten($bgColor, $step);
      }
    }
  }
}

.button {
  $height: 48;
  $height-small: 32;
  display: inline-block;
  text-align: center;
  position: relative;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  transition: 0.1s all ease-in-out;
  border-radius: 5px;

  @include rem(line-height, $height);
  @include rem(height, $height);
  @include rem(padding, 0, 30);
  @include rem(margin-right, 10);
  @include rem(font-size, 14);
  @include button-color(#aaaaaa, white);

  &:before {
    @include rem(font-size, 13);
  }

  &:after {
    @include rem(margin-left, 3);
    @include rem(font-size, 10);
  }

  &:disabled {
    @include button-color(#f0f0f0, #dddddd, 3);
  }

  &:last-child {
    @include rem(margin-right, 0);
  }

  &-primary {
    @include button-color($color-primary);
  }

  &-inverted {
    @include button-color(white);
    color: $color-primary;
    &:hover {
      color: $color-text-muted;
      background: white !important;
    }
  }

  &-inverted-grey {
    @include button-color(white);
    color: #cdcdcd;
    &:hover {
      color: $color-primary;
    }
  }

  &-delete {
    @include button-color($color-secondary);
  }

  &-secondary {
    @include button-color($color-secondary);
  }

  &-transparent {
    background: transparent !important;
    &::before {
      background: transparent !important;
    }
    &::after {
      background: transparent !important;
    }
  }

  &-icon {
    &-transparent {
      &::before {
        background: transparent !important;
      }
      &::after {
        background: transparent !important;
      }
    }
  }

  &-light {
    @include button-color(transparent, $color-text-placeholder);
    border: 1px solid transparent;
    &:disabled {
      @include button-color(transparent, #cccccc);
    }
    &:not(:disabled) {
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid #dddddd;
      }
    }
  }

  &-wide,
  &-wide-long-text {
    display: block;
    width: 100%;
  }

  &-wide-long-text {
    @include rem(padding, 0, 6);
  }

  &-square,
  &-square-large {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rem(width, 48);
    @include rem(height, 48);
    @include rem(margin, 0);
    @include rem(padding, 0);

    &:before {
      display: flex;
      align-items: center;
      margin: 0;
    }

    &.button-small {
      @include rem(width, $height-small);
      @include rem(padding, 0);
    }
  }

  &-square-large {
    @include rem(height, 64);
    @include rem(width, 64);
  }

  &-small {
    @include rem(line-height, $height-small);
    @include rem(height, $height-small);
    @include rem(font-size, 13);
    @include rem(padding, 0, 24);
  }

  &-round {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary !important;
    background: white !important;
    background-color: white !important;
    @include rem(font-size, 14);

    &:before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white !important;
      background: $color-primary !important;
      border-radius: 50%;
      width: 1.5rem !important;
      height: 1.5rem !important;
      @include rem(font-size, 10);
      @include rem(margin-right, 10);
    }
  }
}

.buttonRow {
  display: flex;
  justify-content: space-between;

  &-right {
    justify-content: flex-end;
  }

  &-left {
    justify-content: flex-start;
  }

  .button.button-left {
    margin-right: auto;
  }

  .button.button-right {
    margin-left: auto;
  }
}

.button-rounded {
  height: 38px;
  line-height: 38px;
  padding: 0 40px;
  border-radius: 30px;
  font-size: 14px;
}

.button-cancel {
  color: $color-text;
  background: transparent;
  font-weight: normal;
  text-align: left;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: $color-text;
    background: transparent;
  }
}

.button-danger {
  color: $color-white;
  background: $color-secondary;
}

.button-danger:not(:disabled):hover,
.button-danger:not(:disabled):focus {
  background: darken($color-secondary, 10%);
}

.button-primary {
  background: $color-primary;
  border-radius: 30px;
}

.button-primary:not(:disabled):hover,
.button-primary:not(:disabled):focus {
  background: $color-primary-hover;
}

.button-secondary {
  background: $color-secondary;
  border-radius: 30px;
  color: $color-primary;
}

.button-secondary:not(:disabled):hover {
  background: $color-secondary-hover;
}

.button-default {
  color: $color-primary;
  background-color: $color-white;
  border: 1px solid;
  border-radius: 30px;
}
.button.button-secondary.scanDocBtn {
  width: 160px;
  color: $color-primary;
  &:active {
    color: $color-primary;
  }
  & .icon-scanner {
    font-size: 2.5rem;
    transform: translateY(-12px);
  }
  & .icon-upload {
    font-size: 2rem;
    line-height: 1.6rem;
  }
}

@import '../../../abstracts/variables';

.text-input-component {
  position: relative;
  outline: none;

  input {
    display: block;
    width: 100%;
    height: 48px;
    padding: 10px 0 0;
    border: 0 none;
    border-bottom: 1px solid $color-input-bar;
    border-radius: 0 !important;
    outline: none;
    box-shadow: none;
    color: $color-text;
    letter-spacing: inherit;
  }

  &.box-border {
    .input {
      border: 1px solid #ddd;
      height: 39px;
      padding-left: 15px;
      padding-top: 0px;

      &:focus,
      &:not(.input-empty),
      &-valid:not(.input-empty),
      &-invalid:not(.input-empty) {
        & ~ .input_label {
          top: -62px;
        }
      }

      &_label {
        top: -62px;
        font-size: 13px;
      }

      &_controls {
        margin-top: -10px;
      }
    }
  }
}
.error-top {
  top: 0px;
}

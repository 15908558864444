@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.filter-col {
  padding: 0px 22px 15px 22px;
  display: flex;
  align-items: center;
}
.filter-col > div {
  margin-right: 15px;
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  font-size: 16px;
}
.filter-col > div.active {
  border-color: #dd8927;
  color: #dd8927;
}
.service-engineer {
  margin-top: 20px !important;
}
.service-engineer-name {
  font-size: 18px;
  color: #dd8927;
  font-weight: bold;
}
.service-engineer-add {
  margin-top: 5px;
  font-size: 14px;
  color: #666;
}
.ticket-edit-status span.input_error {
  font-size: 12px;
  min-height: 18px;
  top: -16px;
  padding-top: 10px;
}
.status-heading {
  font-weight: normal;
  font-size: 18px;
  color: #60626e;
  margin-bottom: 10px;
  margin-top: 0;
  flex: 1;
  .status {
    background-color: $color-white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
    &.active {
      background-color: rgba(0, 126, 255, 0.04);
    }
    &.draft {
      color: #c649b2;
    }
    &.inProgress {
      color: #449bec;
    }
    &.open {
      color: #dd8927;
    }
    &.finished {
      color: #50bc5b;
    }
  }
}
.display-totalduration {
  color: #636877;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}
.ticket-box {
  button {
    width: 100%;
  }

  &.pt_37 {
    padding-top: 37px;
  }
}
.sendmail:hover,
.sendmail:active,
.sendmail:focus {
  background: #f0f0f0 !important;
}
.justify-content-between {
  justify-content: space-between;
  padding-top: 5px;
}
.form_input {
  margin-top: 20px;
}

.search-tab-input {
  .icon-search:before {
    font-size: 14px;
    position: absolute;
    top: 10px;
    color: #a0a2a7;
  }
  input {
    padding: 0px 20px;
    width: 100%;
    border: none;
    height: 35px;
  }
  input:focus-visible {
    outline: none;
  }

  .tab-table-input {
    height: 250px;
    overflow: hidden scroll;

    .tbl-varient {
      th {
        padding: 5px 5px !important;
      }
    }
  }

  .input_label {
    top: -50px;
  }
}

.billing {
  margin-top: 50px;
  // .form_input  {
  // 	margin-top: 20px;
  // }
  .label-notes {
    margin-top: -20px;
    display: block;
  }
  .ticket-edit-type-toggle {
    .radio_inputs {
      &:first-child {
        margin-right: 150px;
      }
    }
  }
}
.u_mt_20 {
  margin-top: 20px;
}
.hardware-installation {
  border-collapse: collapse;
  th {
    text-align: left;
  }
  strong,
  span {
    display: block;
  }
  span {
    color: #60626e;
  }
  tbody {
    td {
      border-bottom: 1px solid #000;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
.form-necessary-work {
  .selectInput {
    padding-bottom: 0px !important;
  }
}
.select-defect,
.select-serviceWorks {
  .selectInput {
    margin-top: 32px;
  }
}
.ticket-select-button {
  .Select-control {
    border-bottom: none;
    box-shadow: none !important;
    background: #dd8927 !important;
    text-align: center;
    position: relative;
    border: 0 !important;
    cursor: pointer;
    outline: none;
    font-weight: 600;
    transition: 0.1s all ease-in-out;
    border-radius: 5px !important;
    line-height: 48px;
    height: 48px;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 45px;
  }
  .Select-input {
    height: 45px;
    display: none !important;
  }
  .Select-arrow {
    border-color: $color-white transparent transparent;
  }
  .Select.is-open .Select-arrow {
    border-color: transparent transparent $color-white !important;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    color: $color-white;
  }
}
.list-advanced-pagination-component {
  .selectInput {
    margin-bottom: -10px;
  }
}
.tbl-defects {
  border-collapse: collapse;
  margin-bottom: 30px;
  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 8px;
    text-align: left;
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #000000;
      }
    }
  }
}
.photo-gallery-popups {
  background: $color-white;
  border: 2px dashed $color-secondary;
  box-sizing: border-box;
  box-shadow: 0px 25px 53px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-bottom: 7%;
}
.photo-gallery {
  border: 2px dashed $color-secondary;
  padding: 20px;
  margin-top: 30px;
  border-radius: 16px;
}

.height-150 {
  height: 150px !important;

  img {
    height: 100% !important;
  }
  .photo-uploaded {
    height: 80% !important;
    width: 100%;
    object-fit: contain;
  }
}
.height-110 {
  height: 110px !important;

  img {
    height: 100% !important;
  }
  .photo-uploaded {
    height: 80% !important;
    width: 100%;
    object-fit: contain;
  }
}
.photo-card {
  background: $color-white;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.photo-card img {
  border: 1px solid #000000;
  border-radius: 5px;
  height: 230px;
}
.photo-card .photo-preview {
  height: 185px;
  width: 275px;
  background-size: cover;
}

.photo-card-cus {
  border: 1px solid #aeb5c7;
  border-radius: 5px;
  padding: 10px;

  img {
    border: none !important;
  }
}
.card-action {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.photo-action {
  margin-top: -9px;
  span {
    margin-left: 15px;
    font-size: 17px;
    cursor: pointer;
  }
}
.add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.add-photo img {
  max-width: 150px;
}
.upload-file {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }
}
.photo-action .icon-preview {
  font-size: 38px;
}
.switch-button {
  display: flex;
  align-items: center;
  label {
    line-height: 18px !important;
    margin-right: 10px;
  }
}
.further-work {
  margin-top: 40px !important;
  .form_input {
    margin-top: 0px !important;
  }
  input {
    height: auto;
  }
  .input_label {
    color: #403f3f;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #dd8927;
}

input:focus + .slider {
  box-shadow: 0 0 1px #dd8927;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.image-preview-popup {
  position: relative;
  .icon-close {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: $color-white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
    padding: 8px;
    cursor: pointer;
  }
}
.df-style {
  display: flex;
  flex-direction: column;
}
.ticket-select-button .selectInput {
  padding-bottom: 0 !important;
}
.ticket-date-class .dateInput_wrapper {
  height: 65px;
}
.ticket-date-class .dateInput_wrapper .dateInput_input {
  padding: 10px 0;
}
.ticket-date-class .dateInput_label {
  color: #272d30;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.custom-input {
  text-align: left;
  margin: 20px auto 0;
  display: block;
  width: 235px;
}
.custom-input input[type='text'] {
  padding: 0 10px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px #c9c9c9 solid;
  outline: 0;
  width: 100%;
}
.popupAlertText {
  margin-top: 20px;
  color: #ff0000;
  font-weight: 600;
}
.custom-elipse {
  font-size: 35px;
  transform: rotate(90deg);
}
.customer-delete-list-error {
  color: #ff0000;
  font-weight: 600;
  margin-top: 10px;
}
.ticket-edit-installation .Select-option span {
  display: block;
}

.ticket-edit-installation .Select-option span:first-child {
  font-size: 17px;
  font-weight: 600;
}

.ticket-edit-installation .Select-option span:last-child {
  font-size: 14px;
  color: #999;
}

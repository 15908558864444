.accessories-select-modal {
  margin: 2rem 0 4rem;
  height: calc(60vh - 50px);
  display: flex;
  position: relative;

  .accessories-filters {
    width: 200px;
  }

  .accessories-list {
    flex: 1;
  }
}

.no-device-found-text {
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0 auto;
}

.accessories-image {
  width: 100%;
  justify-items: center;
  display: grid;
}

.accessories-image-content {
  border: 1px solid #aeb5c7;
  box-sizing: border-box;
  border-radius: 5px;
  width: 80%;
  padding-bottom: 30px;
  height: 400px;
}
.accessories-image-content-200 {
  border: 1px solid #aeb5c7;
  box-sizing: border-box;
  border-radius: 5px;
  width: 80%;
  padding-bottom: 30px;
  height: 200px;
}
.accessories-image1 {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
table {
  table-layout: fixed;
  max-width: 100%;
  width: 100%;
}
.tbl-accessoriesgarate {
  text-align: left;
  border-collapse: collapse;

  th {
    padding-bottom: 20px;
    font-size: 14px;
  }
  td,
  th {
    padding: 25px 5px;
    padding-left: 20px;
  }

  tbody {
    tr {
      td {
        strong,
        span {
          display: block;
        }
        strong {
          font-size: 16px;
          font-weight: 700;
        }
        span {
          font-size: 14px;
          color: #60626e;
          font-weight: 600;
        }
      }
    }
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
  }
}

.accessories-edit-component-wrapper {
  .acc-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .acc-devices {
    div {
      display: flex;
      align-items: center;
      width: 100%;
      background: #f7f8fa;
      padding: 12px;
      border-radius: 8px;

      .switch {
        width: 10%;
      }

      p {
        margin: 0px;
        width: 85%;
        margin-left: 5%;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .acc-add-device {
    background: #f7f8fa;
    border-radius: 40px;
    width: 35%;
    align-items: center;
    display: flex;
    padding: 10px;
    justify-content: center;
    cursor: pointer;

    .icon.icon-add-circle {
      color: $color-secondary;

      &:before {
        font-size: 25px;
      }
    }
    .text-content {
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      color: $color-secondary;
      padding-left: 10px;
    }
  }
}

@import '../../abstracts/_variables';
@import '../../abstracts/_mixins';

.notes {
  pre {
    overflow: visible;
  }

  &_empty {
    color: silver;
    background-color: #f7f8fa;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 20px;
  }

  &_actions {
    position: absolute;
    top: 0;
    right: 0;
  }

  &_heading {
    margin-bottom: 12px;
  }

  &_content {
    &.row > :first-child {
      word-wrap: break-word;
      padding-right: 8px;
    }
  }

  & .form {
    position: relative;
    margin: 7px -9px;
  }

  &Edit {
    .toggleInput {
      position: relative;
      padding: 40px 0px 15px 0px;
      align-items: center;

      .notes-alert-label {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #40444e;
      }
    }
    .switchInput {
      .notes-alert-label {
        font-size: 14px;
        color: $color-text-muted;
      }

      .customer-edit-notes-alert-toggle {
        position: absolute;
        width: 90px;
        top: 0;
        right: 0;
      }
    }

    &_content {
      position: relative;
    }

    &_controls {
      position: absolute;
      display: flex;
      right: 0;
      z-index: 10;
      bottom: -56px;

      .button:first-of-type {
        margin-right: 6px;
      }
    }
  }
}
.note-box {
  padding: 0;
}

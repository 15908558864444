.detail-variant-modal {
  &-inAction {
    color: red !important;
  }
}

.detail-container {
  &-row {
    display: flex;

    &-title {
      margin: 0px;
      width: 25%;
    }
    &-value {
      padding-left: 30px;
      margin: 0px;
    }
  }
}
.detail-table-cell {
  &-value {
    color: #000 !important;
    margin-left: 10px;
  }

  &-title {
    font-weight: 600;
    color: #0a0a0a !important;
  }
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

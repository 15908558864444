@import '../../../abstracts/variables';
@import '../../../abstracts/mixins';

.landing-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .landing-sidebar {
    position: relative;
    width: 40%;
    height: 100%;
    float: left;
    background-color: #adbbbe;
    background-image: url(../../../../assets/images/svg/login_beratertool.svg);
    background-size: cover;

    .landing-sidebar-footer {
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;

      a {
        color: $color-white;
        font-size: 13px;
      }

      .link-divider {
        display: inline-block;
        height: 13px;
        margin: 0 12px -2px;
        border-left: 1px solid $color-white;
      }
    }

    .landing-fact-imprezz-logo {
      position: absolute;
      left: 46%;
      right: 40%;
      top: 30%;
      transform: translateY(-50%);
    }

    .auric-small-image {
      border-radius: 20%;
      width: 60px;
    }

    .landing-fact {
      position: absolute;
      min-width: 330px;
      width: 100%;
      top: 40%;
      text-align: center;
      @include vendor-prefix('transform', 'translateY(-50%)');

      .landing-fact-icon-background {
        width: 180px;
        height: 180px;
        margin: 0 auto;
        border-radius: 50%;
      }

      .landing-fact-icon {
        position: absolute;
        top: 45px;
        left: 50%;
        @include vendor-prefix('transform', 'translateX(-50%)');
        height: 90px;
      }

      .landing-fact-text {
        position: absolute;
        left: 30px;
        right: 30px;
        margin: 40px 0 0;
        color: $color-white;
        font-style: italic;
        font-size: 17px;
        line-height: 27px;
      }

      .landing-fact-subtext {
        position: absolute;
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  .landing-content {
    position: relative;
    width: 60%;
    height: 100%;
    float: left;
    background: $color-white;

    .landing-content-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 700px;
      text-align: center;
      @include vendor-prefix('transform', 'translate(-50%, -50%)');

      .landing-content-headline {
        margin-bottom: 10px;
        font-size: 48px;
        font-weight: 600;

        &.alert {
          color: $color-error;
        }

        .landing-activation-email {
          color: $color-primary;
          word-break: break-all;
        }
      }

      .landing-content-sub-headline {
        margin-bottom: 50px;
        font-size: 18px;
        font-weight: 500;
        color: #aeb5c7;
        max-width: 60ch;
        margin-inline: auto;
      }

      .button {
        margin: 30px 0 18px 0;
      }

      .landing-pick-legalform {
        .landing-pick-legalform-box-wrapper {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;

          .landing-pick-legalform-box {
            position: relative;
            width: 45%;
            padding: 25px 10px 0;
            height: 150px;
            cursor: pointer;
            color: $color-text-light;

            .landing-legalform-answer-headline {
              font-size: 24px;
              font-weight: 600;
            }

            .landing-legalform-answer-subheadline {
              margin-top: 3px;
              font-size: 12px;
            }

            .landing-legalform-answer-text {
              position: absolute;
              bottom: 30px;
              left: 0;
              width: 100%;
              font-size: 12px;
            }

            &.active,
            &:hover {
              background: $color-bg-medium;

              .landing-legalform-answer-headline {
                color: $color-primary;
              }
            }
          }

          .landing-pick-legalform-box-right .landing-legalform-answer-text {
            bottom: 15px;
          }
        }

        .button-component-wrapper {
          margin-top: 15px;
        }

        .selectInput {
          text-align: left;
        }
      }

      p {
        position: relative;
        color: $color-text-light;

        &.landing-password-validity {
          font-size: 14px;
          margin: 20px 0;
          text-align: left;

          &.alert {
            color: $color-error;
          }
        }
      }

      .landing-email-input-wrapper {
        width: 450px;
        margin: 0 auto;

        .form_input {
          margin-bottom: 20px;
        }

        .button-between-label {
          color: #ccc;
        }

        .button {
          width: 450px;
          margin: 18px 0;
        }

        .forgot-link {
          float: left;
          color: #232c45;
          font-weight: 600;
        }
        .GoBack-link {
          color: #232c45;
          font-weight: 600;
        }
      }

      .landing-password-check {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $color-input-bar;

        &.checked {
          border: 1px solid $color-primary;
          background: $color-primary;
        }

        &.alert {
          border: 1px solid $color-error;
          background: $color-error;
        }

        .icon {
          &.icon-check {
            display: block;
            color: $color-white;
            font-size: 10px;
            margin: 4px;
          }

          &.icon-close {
            display: block;
            color: $color-white;
            font-size: 10px;
            margin: 4px;
          }
        }
      }

      .input[type='password'] {
        letter-spacing: inherit;
      }

      .landing-password-error {
        position: absolute;
        margin-top: -15px;
        font-size: 14px;
        color: $color-error;
      }

      .landing-email-error {
        font-size: 14px;
        margin-top: -10px;
        color: $color-error;
        text-align: left;

        &.invalid-email,
        &.invalid-password {
          position: absolute;
          width: 390px;
          text-align: center;
        }

        &.invalid-password {
          width: 100%;
        }
      }

      .landing-mobile-textInfo {
        text-align: left;
      }

      .landing-mobile-error {
        font-size: 14px;
        color: $color-error;
        text-align: left;

        &.invalid-mobile {
          position: absolute;
          width: 100%;
          text-align: center;
        }
      }

      .landing-password-mismatch {
        .form_input input {
          color: $color-error;
        }
      }

      .landing-activation-input {
        display: table;
        position: relative;
        margin: 25px auto 0;

        .landing-activation-error {
          position: absolute;
          bottom: -25px;
          width: 100%;
          text-align: center;
          color: $color-error;
        }

        .form_input {
          position: relative;
          width: 30px;
          float: left;
          margin: 0 20px;

          input {
            text-align: center;
            font-size: 30px;
            font-weight: 600;
          }
        }
      }
    }

    .landing-content-footer {
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 600;

      .landing-content-footer-item {
        display: inline-block;
        margin: 0 20px;
      }
    }

    .invoiz-logo {
      width: 270px;
      margin: 149px auto;
    }

    .approval-hint-modal {
      position: fixed;
      right: 26px;
      bottom: 73px;
      width: 348px;
      min-width: 0;
      min-height: 348px;
      font-size: 13px;
      padding: 32px 40px;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      background: #f7f7f7;

      .icon-close {
        position: absolute;
        top: 18px;
        right: 18px;
        font-size: 16px;
        color: #7f7f7f;
        cursor: pointer;
      }

      .text-block {
        padding-top: 20px;
        color: $color-text;
      }

      .hints-block {
        padding-top: 5px;

        .hints-row {
          padding-top: 15px;
          display: flex;

          .hints-col-left {
            width: 25px;
            height: 25px;
            line-height: 23px;
            margin-right: 20px;
            border-radius: 50%;
            color: $color-white;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            background: $color-primary;
          }

          .hints-col-right {
            width: 220px;

            a {
              font-weight: 600;
            }

            u {
              word-break: break-all;
            }
          }

          &.second-row {
            .hints-col-right {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .landing-responsive-element {
    display: none;
  }
}

@media (max-height: 900px) {
  .invoiz-logo {
    width: 25vh !important;
    margin: 9.5vh auto 14.5vh auto !important;
  }
}

@media (max-width: 650px) {
  .landing-wrapper {
    display: flex;
    flex-direction: column-reverse;

    &.landing-state-start {
      .landing-responsive-element {
        color: $color-text-light;
        margin: 20px 0 10px 0;
        font-size: 13px;
        line-height: 22px;
      }
    }

    &.landing-state-password {
      .landing-content {
        .landing-content-inner {
          .button {
            margin: 20px 0;
          }
        }
      }
    }

    .landing-responsive-element {
      display: block;
    }

    .landing-content {
      width: 100%;

      .invoiz-logo {
        width: 115px;
        margin: 40px auto;
      }

      .landing-content-inner {
        top: 100px;
        @include vendor-prefix('transform', 'translate(-50%, 0%)');

        .landing-content-headline {
          font-size: 22px;
          margin-bottom: 15px;
        }

        p.landing-password-validity {
          margin: 8px 0;

          .landing-password-check {
            transform: scale(0.8);
          }
        }
      }

      .landing-content-footer {
        bottom: 10px;
      }
    }

    .landing-sidebar {
      width: 100%;
      height: 50px;
      background: $color-white;

      .landing-sidebar-footer {
        bottom: 20px;

        a {
          color: $color-text-light;
        }

        .link-divider {
          border-color: $color-text-light;
        }
      }

      .landing-fact,
      .landing-fact-imprezz-logo {
        display: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .landing-email-input-wrapper {
    width: 320px !important;
  }

  .landing-wrapper {
    position: relative !important;
  }

  .layout-blank,
  .layout-blank > section,
  .layout > section,
  .layout-blank > section .page {
    height: 100%;
  }

  /*.landing-content-inner {
		width: 320px!important;
		position: relative!important;
		transform: none!important;
		left: auto!important;
		top: auto!important;
		margin: 0 auto 30px!important;
	}*/
  .landing-content-inner {
    position: relative !important;
    width: 100% !important;
    padding: 0 20px;
    transform: none !important;
    left: auto !important;
    top: auto !important;
    margin: 0 auto 30px !important;
  }

  .landing-content-footer {
    position: relative !important;
    font-weight: 500 !important;
    bottom: auto !important;
    margin-bottom: 10px !important;
  }

  .landing-sidebar-footer {
    position: relative !important;
    bottom: auto !important;
  }

  .landing-email-error {
    margin-top: -19px !important;
  }

  .landing-email-error.invalid-email,
  .landing-email-error.invalid-password {
    width: 100% !important;
    margin-top: -13px !important;
  }

  .landing-mobile-error {
    margin-top: -19px !important;
  }

  .landing-mobile-error.invalid-mobile {
    width: 100% !important;
    margin-top: -13px !important;
  }

  .landing-responsive-element {
    margin-top: 10px !important;
  }
}
